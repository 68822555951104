import {Link} from "react-router-dom";
import React from "react";

const ReportSidebar = () => {
    return(
        <div className="col-xl-2 d-print-none">
            <div className="card card-flush">
                <div className="card-header pt-7" id="kt_chat_contacts_header">
                    <div className="card-title" style={{borderBottom: '1px solid #CCCCCC'}}>
                        <h2>Menu</h2>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="d-flex flex-column gap-5">
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/payroll-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Payroll Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/bank-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Bank Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/paye-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">PAYE Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/wht-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">WHT Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/pension-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Pension Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/nhf-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">NHF Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/nhis-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">NHIS Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/nsitf-schedule" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">NSITF Schedule</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/payroll-journal-voucher" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Payroll Journal Voucher</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/payroll-report-by-paypointe" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Payroll Report By Paypoint</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/allowance-and-deduction-report" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Allowance & Deduction Report</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/payroll/paypoint/report-by-staff" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Staff Monthly Gross/Net Pay Report</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/staff-verification-list" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Staff Verification List</Link>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-stack">
                            <Link to="/report/custom-report" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Custom Report</Link>
                        </div>
                    </div>
                    <div className="separator my-7"></div>
                    <Link to="/report/dashboard" class="btn btn-primary w-100">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                              <rect x="5" y="5" width="5" height="5" rx="1" fill="currentColor"></rect>
                                              <rect x="14" y="5" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                              <rect x="5" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                              <rect x="14" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                          </g>
                                      </svg>
								</span>Report Dashboard</Link>
                </div>
            </div>
        </div>
    )
}
export default ReportSidebar;