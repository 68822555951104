import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import { API_URL } from "../../../context/connection-string";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";
import { formatDateAndTime } from "../../../context/function";
import AllRoomBookingCalenderView from "../../front-desk/booking/room-calender-view/all-room-booking-calender-view";
import { DashboardPageTitle } from "./dashboard-page-title";
import Svg from "./svg";
import Chart from "react-google-charts";

function DashboardFacilityAndServices({ loginData }) {
    const [isLoading, setIsLoading] = useState(true);
    const [roomData, setRoomData] = useState([["Room Status", "Count"]])
    const [roomTypeData, setRoomTypeData] = useState([["Room Type", "Count"]])
    const [galleryData, setGalleryData] = useState([["Gallery Type", "Count"]])

    const [stats, setStats] = useState({
        floor_list: 0, facility_list: 0, block_list: 0, service_list: 0, service_request_processed: 0, service_request_completed: 0, service_request_pending: 0, facility_request_list: 0, facility_request_approved: 0, facility_request_pending: 0, facility_request_completed: 0, facility_request_cancelled: 0,
    })

    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/facility-service-dashboard/${loginData[0].branch_id}`, loginData[0].token)
            .then(res => {

                //ROOM CHART START
                const room = res.data.room_list;
                let room_data = [
                    ...roomData
                ]
                if (room.length > 0) {
                    const unique_room_status = [...new Set(room.map(item => item.status))];
                    unique_room_status.map(r => {
                        return room_data.push([r, room.filter(e => e.status === r).length])
                    })
                }
                setRoomData(room_data)
                //ROOM CHART END

                //ROOM TYPE CHART START
                const room_type = res.data.room_type_list;
                let room_type_data = [
                    ...roomTypeData
                ]
                if (room_type.length > 0) {
                    const unique_room_type_status = [...new Set(room_type.map(item => item.type_name))];
                    unique_room_type_status.map(r => {
                        return room_type_data.push([r, room_type.filter(e => e.type_name === r).length])
                    })
                }
                setRoomTypeData(room_type_data)
                //ROOM TYPE CHART END


                //GALLERY TYPE CHART START
                const gallery = res.data.gallery_list;
                let gallery_data = [
                    ...galleryData
                ]
                if (gallery.length > 0) {
                    const unique_gallery_status = [...new Set(gallery.map(item => item.gallery_type))];
                    unique_gallery_status.map(r => {
                        return gallery_data.push([r, gallery.filter(e => e.gallery_type === r).length])
                    })
                }
                setGalleryData(gallery_data)
                //ROOM TYPE CHART END

                setStats({
                    floor_list: res.data.floor_list.length,
                    block_list: res.data.block_list.length,
                    facility_list: res.data.facility_list.length,
                    service_list: res.data.service_list.length,

                    service_request_list: res.data.service_request_list.length,
                    service_request_pending: res.data.service_request_list.filter(i => i.status === 'pending').length,
                    service_request_processed: res.data.service_request_list.filter(i => i.status === 'processed').length,
                    service_request_completed: res.data.service_request_list.filter(i => i.status === 'completed').length,

                    facility_request_list: res.data.facility_request_list.length,
                    facility_request_approved: res.data.facility_request_list.filter(i => i.status === 'Approved').length,
                    facility_request_pending: res.data.facility_request_list.filter(i => i.status === 'Pending').length,
                    facility_request_completed: res.data.facility_request_list.filter(i => i.status === 'Completed').length,
                    facility_request_cancelled: res.data.facility_request_list.filter(i => i.status === 'Cancelled').length,




                })
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useState(() => {
        fetchRecord()
    }, [])

    return isLoading ? <Loader /> : (
        <>
            <DashboardPageTitle title="Facility/Services Department" />
            <div className="row g-5 g-xl-12 pt-5">
                <div className="col-xl-4">
                    <span className="card bg-dark hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                        d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                        fill="currentColor" />
                                    <path
                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.block_list}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Total Number of Block</div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-4">
                    <span className="card bg-dark hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                        d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                        fill="currentColor" />
                                    <path
                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.floor_list}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Total Number of Floor</div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-4">
                    <span className="card bg-dark hoverable mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Svg />
                                    <div className="text-white fw-bold fs-1 mb-2 mt-5">{stats.facility_list}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Number of Facility</div>
                                </div>
                                <div>
                                    <Svg />
                                    <div className="text-white fw-bold fs-1 mb-2 mt-5">{stats.service_list}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Number of Service</div>
                                </div>
                            </div>
                        </div>
                    </span>

                </div>
            </div>

            <div className="row g-5 g-lg-12 ">
                <div className="col-xxl-6 col-md-6 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">
                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-dark">
                                <div className="d-flex flex-stack">
                                    <h3 className="m-0 text-white fw-bold fs-3">Facility Request</h3>
                                </div>
                                <div className="d-flex text-center flex-column text-white pt-8">
                                    <span className="fw-semibold fs-7">Total Number of Facility Requested</span>
                                    <span className="fw-bold fs-2x pt-1">{stats.facility_request_list}</span>
                                </div>
                            </div>
                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: '-100px' }}>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Completed</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.facility_request_completed}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Approved</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.facility_request_approved}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Pending</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.facility_request_pending}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Cancelled</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.facility_request_cancelled}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-6 col-md-6 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">
                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-dark">
                                <div className="d-flex flex-stack">
                                    <h3 className="m-0 text-white fw-bold fs-3">Service Request</h3>
                                </div>
                                <div className="d-flex text-center flex-column text-white pt-8">
                                    <span className="fw-semibold fs-7">Total Number of Service Requested</span>
                                    <span className="fw-bold fs-2x pt-1">{stats.service_request_list}</span>
                                </div>
                            </div>
                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: '-100px' }}>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Completed</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.service_request_completed}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Pending</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.service_request_pending}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Processed</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.service_request_processed}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-5 g-lg-12">
                {
                    roomData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={roomData}
                            options={{ title: "Room Status", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

{
                    roomTypeData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={roomTypeData}
                            options={{ title: "Room Type", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }


{
                    galleryData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={galleryData}
                            options={{ title: "Gallery Type", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardFacilityAndServices);
