import React  from 'react';
import Modal from 'react-bootstrap/Modal';

export default function BootstrapModal(props) {
    const onClose = () => {
      props.closeModal(false)
    }
    return (
        <>
            <Modal size={typeof props.size !== "undefined" ? props.size : 'lg'} show={props.openModal} onHide={onClose} aria-labelledby="bootstrap-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="bootstrap-modal">
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        </>
    );
}
