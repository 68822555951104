import TextEditor from "../../../component/text-editor/text-editor";
import StateData from "../../../context/state-and-lga.json";
import {useState} from "react";
export default function BranchForm(props) {
    const city_list = props.value.state !== "" ? StateData.filter(i => i.state === props.value.state)[0].lgas : []
    const [CityData, setCityData] = useState(city_list)
    const onStateChange = (e) => {
        setCityData(StateData.filter(i => i.state === e.target.value)[0].lgas)
        props.onChange(e)
    }

    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="branch_name">Branch Name</label>
                        <input type="text" id="branch_name" className="form-control" value={props.value.branch_name} onChange={props.onChange}/>
                    </div>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="address">Branch Address</label>
                        <input type="text" id="address" className="form-control" value={props.value.address} onChange={props.onChange}/>
                    </div>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <select id="state" className="form-control" defaultValue={props.value.state} onChange={onStateChange}>
                            <option value="">Select State</option>
                            {
                                StateData.map((r,i) => {
                                    return <option key={i} value={r.state}>{r.state}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <select id="city" className="form-control" defaultValue={props.value.city} onChange={props.onChange}>
                            <option value="">Select City</option>
                            {
                                CityData.map((r,i) => {
                                    return <option key={i} value={r}>{r}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="email_address">Email Address</label>
                        <input type="email" id="email_address" className="form-control" value={props.value.email_address} onChange={props.onChange}/>
                    </div>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input type="tel" id="phone_number" className="form-control" value={props.value.phone_number} onChange={props.onChange}/>
                    </div>
                </div>
                <div className="col-md-4 pb-3">
                    <div className="form-group">
                        <label htmlFor="manager_id">Branch Manager</label>
                        <select id="manager_id" className="form-control" defaultValue={props.value.manager_id} onChange={props.onChange}>
                            <option value="">Select Branch Manager</option>
                            {
                                props.employeeList.length > 0 &&
                                    props.employeeList.map((r,i) => {
                                        return <option key={i} value={r.employee_id}>{`${r.full_name} => ${r.branch_name}`}</option>
                                    })
                            }
                        </select>
                    </div>
                </div>
                <div className="col-md-4 pb-3">
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select id="status" className="form-control" defaultValue={props.value.status} onChange={props.onChange}>
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 pb-3">
                    <div className="form-group">
                        <label htmlFor="map_url">Map URL</label>
                        <input type="url" id="map_url" className="form-control" value={props.value.map_url} onChange={props.onChange}/>
                    </div>
                </div>
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="status">Branch Description</label>
                        <TextEditor description={props.value.description} result={props.onEditorData} />
                    </div>
                </div>

                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
