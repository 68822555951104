import {Link} from "react-router-dom";
import React from "react";

const PayrollSidebar = () => {
  return(
      <div className="col-xl-2 no-printing">
          <div className="card card-flush">
              <div className="card-header pt-7" id="kt_chat_contacts_header">
                  <div className="card-title" style={{borderBottom: '1px solid #CCCCCC'}}>
                      <h2>Menu</h2>
                  </div>
              </div>
              <div className="card-body pt-5">
                  <div className="d-flex flex-column gap-5">
                      <div className="separator"></div>
                      {/*<div className="d-flex flex-stack">*/}
                      {/*    <Link to="/human-resources/salary-settings" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Salary Settings</Link>*/}
                      {/*</div>*/}
                      <div className="separator"></div>
                      <div className="d-flex flex-stack">
                          <Link to="/human-resources/ledger-accounts" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Allowances & Deductions</Link>
                      </div>
                      <div className="separator"></div>
                      <div className="d-flex flex-stack">
                          <Link to="/human-resources/manage-allowance-and-deduction" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Manage Allowances & Deductions</Link>
                      </div>
                      <div className="separator"></div>
                      <div className="d-flex flex-stack">
                          <Link to="/human-resources/run-allowance-and-deduction" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Run Allowances & Deductions</Link>
                      </div>
                      <div className="separator"></div>
                      <div className="d-flex flex-stack">
                          <Link to="/human-resources/run-salary" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Run Salary</Link>
                      </div>
                      <div className="separator"></div>
                      <div className="d-flex flex-stack">
                          <Link to="/human-resources/salary-report" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Print Pay Slip</Link>
                      </div>
                      <div className="separator"></div>
                      <div className="d-flex flex-stack">
                          <Link to="/human-resources/multiple-pay-slip" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Print Multiple Pay Slip</Link>
                      </div>
                      {/*<div className="separator"></div>*/}
                      {/*<div className="d-flex flex-stack">*/}
                      {/*    <Link to="/human-resources/pay-register" className="fs-6 fw-bold text-gray-800 text-hover-primary text-active-primary active">Pay Register</Link>*/}
                      {/*</div>*/}
                  </div>
                  <div className="separator my-7"></div>
                  <Link to="/human-resources/payroll" class="btn btn-primary w-100">
                                <span class="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                              <rect x="5" y="5" width="5" height="5" rx="1" fill="currentColor"></rect>
                                              <rect x="14" y="5" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                              <rect x="5" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                              <rect x="14" y="14" width="5" height="5" rx="1" fill="currentColor" opacity="0.3"></rect>
                                          </g>
                                      </svg>
								</span>Payroll Dashboard</Link>
              </div>
          </div>
      </div>
  )
}
export default PayrollSidebar;
