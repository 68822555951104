import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import {API_URL, HOTEL_ADDRESS, HOTEL_NAME} from '../../../context/connection-string';
import {currencyConverter, formatDateAndTime, moneyFormat} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import Logo from "../../../assets/img/logoo-.png"
import "./style.css"
function PayrollJournalVoucher(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const header = ["S/N", "Staff Number", "Surname",  "OtherNames", "Net Pay", "Banker", "Account Number", "Sort Code"];
    const [reportData, setReportData] = useState({
        PAYE: 0,
        Gross_Pay: 0,
        Net_Pay: 0,
        staff_coporative: 0,
        NHF: 0,
        NHIS: 0,
        staff_salary_advance: 0,
        student_receivable: 0,
        other_income: 0,
        employee_contributions: 0,
        employer_contributions: 0,
        total_contributions: 0,
        staff_salary_payable: 0,
        dr_total: 0,
        cr_total: 0
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }

    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {

        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/payroll/journal/voucher?salary_month=${salary_month}`, login?.token)
                .then((res) => {
                    setReportData(res.data)
                    setIsSubmitting(false)
                    setIsShow(true)
                }).catch((e) => {
                    setIsSubmitting(false)
                    setIsShow(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
        getSalaryReport(val)
    }


    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Payroll Journal Voucher"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                                    { name: 'Payroll Journal Voucher', link: '/report/payroll-journal-voucher' }]}
                />

                <div className="row">
                    <ReportSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5 d-print-none col-md-10'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                   className="form-control"
                                                   value={formData.month_id}
                                                   onChange={onChange}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                isShow ?
                                                    <div className="d-flex flex-column flex-xl-row margin-up">
                                                        <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                                            <div className="mt-n1">
                                                                <div className="d-flex flex-stack pb-10 d-flex  align-items-center  justify-content-center">
                                                                    <div style={{ fontSize: "35px" }} className="text-center text-gray-800" >
                                                                        <strong>{HOTEL_NAME}</strong>
                                                                        <div className="text-center fw-semibold fs-4 text-gray-900 mb-3">
                                                                            OFFICE OF THE BURSAR
                                                                        </div>
                                                                        <h3 className="text-center text-gray-800 text-uppercase">PAYROLL MEMORANDUM FOR {formatDateAndTime(formData.month_id, 'month_and_year')}</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="m-0">
                                                                    <div className="fw-bold fs-6 text-gray-600 mb-8 text-end">Date: {formatDateAndTime(formData.month_id, 'date')}</div>
                                                                    <div className="flex-grow-1">
                                                                        <div className="table-responsive border-bottom mb-9">
                                                                            <table className="table table-bordered mb-5" style={{border: '1px solid #cccccc', paddingLeft: '15px'}}>
                                                                                <thead>
                                                                                <tr style={{border: '1px solid #9c9897', backgroundColor: '#cccccc', textAlign: 'center'}}>
                                                                                    <th colSpan={4} style={{ padding: '12px', fontSize: '18px' }}><b>Employee Payroll-Related Costs</b></th>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #9c9897'}}>
                                                                                    <th style={{ padding: '12px' }} width={40}><b>S/N</b></th>
                                                                                    <th style={{ padding: '12px' }}><b>Description</b></th>
                                                                                    <th><b>Amount(N)</b></th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <th style={{ padding: '12px' }}>1</th>
                                                                                    <td>Gross Salaries and Wages</td>
                                                                                    <td>{moneyFormat(reportData.Gross_Pay)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <th style={{ padding: '12px' }}>2</th>
                                                                                    <td>Employer Pension Contribution</td>
                                                                                    <td>{moneyFormat(reportData.employer_contributions)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <th style={{ padding: '12px' }}>3</th>
                                                                                    <td>NSITF Contribution</td>
                                                                                    <td>{moneyFormat(reportData.NSITF)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <th style={{ padding: '12px' }}>4</th>
                                                                                    <td>Total Allowances</td>
                                                                                    <td>{moneyFormat(reportData.non_standard_allowances)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <th></th>
                                                                                    <td style={{ padding: '12px', fontSize: '16px' }}><b>Total</b></td>
                                                                                    <td><b>{moneyFormat(reportData.dr_total)}</b></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={4}>&nbsp;</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #9c9897', backgroundColor: '#cccccc', textAlign: 'center'}}>
                                                                                    <th colSpan={4} style={{ padding: '12px', fontSize: '18px' }}><b>Distribution</b></th>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #9c9897'}}>
                                                                                    <th style={{ padding: '12px' }} width={40}><b>S/N</b></th>
                                                                                    <th style={{ padding: '12px' }}><b>Description</b></th>
                                                                                    <th><b>Amount(N)</b></th>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>1</td>
                                                                                    <td>NHF: Housing Deduction</td>
                                                                                    <td>{moneyFormat(reportData.NHF)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>2</td>
                                                                                    <td>NHIS: Staff Medical Deduction</td>
                                                                                    <td>{moneyFormat(reportData.NHIS)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>3</td>
                                                                                    <td>Pension: Employer & Employee</td>
                                                                                    <td>{moneyFormat(reportData.total_contributions)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>4</td>
                                                                                    <td>NSITF Contribution</td>
                                                                                    <td>{moneyFormat(reportData.NSITF)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>5</td>
                                                                                    <td>PAYE and WHT</td>
                                                                                    <td>{moneyFormat(reportData.PAYE)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>6</td>
                                                                                    <td>Staff Cooperative</td>
                                                                                    <td>{moneyFormat(reportData.staff_coporative)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>7</td>
                                                                                    <td>Staff Cooperative Loan</td>
                                                                                    <td>{moneyFormat(reportData.staff_coporative_loan)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>8</td>
                                                                                    <td>Staff Salary Advance</td>
                                                                                    <td>{moneyFormat(reportData.staff_salary_advance)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>9</td>
                                                                                    <td>School Fee Deduction (UG)</td>
                                                                                    <td>{moneyFormat(reportData.school_deduction_fee_ug)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>10</td>
                                                                                    <td>School Fee Deduction (PG)</td>
                                                                                    <td>{moneyFormat(reportData.school_deduction_fee_pg)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>11</td>
                                                                                    <td>Other Deductions</td>
                                                                                    <td>{moneyFormat(reportData.other_deduction)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td style={{ padding: '12px' }}>12</td>
                                                                                    <td>Net Salaries and Wages</td>
                                                                                    <td>{moneyFormat(reportData.Net_Pay)}</td>
                                                                                </tr>
                                                                                <tr style={{border: '1px solid #cccccc'}}>
                                                                                    <td></td>
                                                                                    <td style={{ padding: '12px' }}><b>Total</b></td>
                                                                                    <td><b>{moneyFormat(reportData.cr_total)}</b></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan={3}>&nbsp;</td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="col-md-3 offset-sm-5 d-print-none">
                                                                            <button className="form-control btn btn-primary" onClick={()=>window.print()}>Print</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
																		<path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
																	</svg>
																</span>
                                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                                <h4 className="text-gray-900 fw-bold">Please select salary month to view report</h4>
                                                                {/*<div className="fs-6 text-gray-700 pe-7">Please select salary month to view report</div>*/}
                                                            </div>

                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(PayrollJournalVoucher);
