import React  from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../action/set-action-details";

function ResetPassword(props) {

    return (
        <>
            <h1>RESET PASSWORD</h1>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);