import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import { API_URL } from "../../../context/connection-string";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";
import { currencyConverter, currentDate, formatDateAndTime, monthNames } from "../../../context/function";
import { DashboardPageTitle } from "./dashboard-page-title";
import Svg from "./svg";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Chart from "react-google-charts";



function DashboardPOS({ loginData }) {
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({
        totalRoom: 0, availableRoom: 0, checkInList: 0, checkOutList: 0, cancelledList: 0, latestBooking: 0,
        all_items: 0, available_items: 0, sales: '', sales_today: "", most_ordered: [], last_ten_orders: [], last_ten_order_details: [],
        monthly_sales_graph: []
    })
    const [MonthlyData, setMonthlyData] = useState([["Month", "Total", "Paid", "Outstanding"]])
    const [byPaymentMethod, setbyPaymentMethod] = useState([["Payment Method", "Total", "Paid", "Outstanding"]])


    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/pos-dashboard/${loginData[0].branch_id}`, loginData[0].token)
            .then(res => {
                let by_payment_method = [
                    ...byPaymentMethod,
                ]
                if (res.data.sales_by_payment_method.length > 0) {
                    res.data.sales_by_payment_method.map((r, i) => {
                        return by_payment_method.push([
                            r.payment_method,
                            r.amount_expected,
                            r.amount_paid,
                            r.customer_outstanding
                        ])
                    })
                }
                setbyPaymentMethod(by_payment_method)

                const monthly_sales = res.data.monthly_sales;
                let sales = [
                    ...MonthlyData
                ];
                if (monthNames.length > 0) {
                    monthNames.map((r, i) => {
                        return sales.push([
                            monthNames[i],
                            monthly_sales.filter(e => e.curr_month === i + 1)[0]?.total_sales,
                            monthly_sales.filter(e => e.curr_month === i + 1)[0]?.amount_paid,
                            monthly_sales.filter(e => e.curr_month === i + 1)[0]?.customer_outstanding
                        ])
                    })
                }
                setMonthlyData(sales)



                setStats({
                    ...stats,
                    available_items: res.data.available_items.length,
                    all_items: res.data.all_items.length,
                    sales: res.data.all_sales[0],
                    sales_today: res.data.sales_today[0],
                    most_ordered: res.data.most_ordered,
                    last_ten_orders: res.data.last_ten_orders,
                    last_ten_order_details: res.data.last_ten_order_details
                })
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useState(() => {
        fetchRecord()
    }, [])



    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: "14px",
            minWidth: 350,
            border: '1px solid #dadde9',
        },
    }));

    const OrderItems = (items = []) => {
        return (
            <div className="table-responsive">
                <table className={"table table-bordered table-row-bordered table-striped "} style={{ border: '1px solid #eeeeee' }}>
                    <thead>
                        <tr style={{ fontWeight: "bold" }}>
                            <th>Item</th>
                            <th>Unit Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items.length > 0 &&
                            items?.map((d, p) => {
                                return (
                                    <tr key={p} className="p-3">
                                        <td>{d.item_name}</td>
                                        <td>{currencyConverter(d.unit_price)}</td>
                                        <td>{d.quantity}</td>
                                        <td>{currencyConverter(d.total)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }


    return isLoading ? <Loader /> : (
        <>
            <DashboardPageTitle title="POS Department" />
            <div className="row g-5 g-xl-12 pt-5">
                <div className="col-xl-4">
                    <span className="card bg-success hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Svg />
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.all_items}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Items</div>
                                </div>
                                <div className="text-end">
                                    <Svg />
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.available_items}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Available Items</div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-4">
                    <span className="card bg-primary hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                        <Svg />
                                    </span>
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{currencyConverter(stats.sales?.total_sales)}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Sales</div>
                                </div>
                                <div className="text-center">
                                    <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                        <Svg />
                                    </span>
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{currencyConverter(stats.sales?.amount_paid)}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Paid</div>
                                </div>
                                <div className="text-end">
                                    <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                        <Svg />
                                    </span>
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{currencyConverter(stats.sales?.customer_outstanding)}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Total Outstanding</div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-4">
                    <span className="card bg-dark hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                        <Svg />
                                    </span>
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{currencyConverter(stats.sales_today?.total_sales)}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Sales Today</div>
                                </div>
                                <div className="text-center">
                                    <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                        <Svg />
                                    </span>
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{currencyConverter(stats.sales_today?.amount_paid)}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Paid Today</div>
                                </div>
                                <div className="text-end">
                                    <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                        <Svg />
                                    </span>
                                    <div className="text-white fw-bold fs-2 mb-2 mt-5">{currencyConverter(stats.sales_today?.customer_outstanding)}</div>
                                    <div className="text-white fw-bold fs-2 mb-2">Outstanding Today</div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div className="row g-5 g-lg-12 ">
                <div className="col-xxl-4 col-md-4 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">

                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-primary">
                                <div className="d-flex flex-stack">
                                    <h3 className="m-0 text-white fw-bold fs-3">Most Ordered Items</h3>
                                </div>
                                {
                                    stats.most_ordered.length > 0 &&
                                    <div className="d-flex text-center flex-column text-white pt-8">
                                        <span className="fw-semibold fs-7">
                                            {stats.most_ordered[0].item_name}
                                        </span>
                                        <span className="fw-bold fs-2x pt-1">{stats.most_ordered[0].item_count}</span>
                                    </div>
                                }
                            </div>

                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: '-100px' }}>
                                {
                                    stats.most_ordered.length > 0 ?
                                        stats.most_ordered.filter((x, i) => i !== 0).map((m, j) => {
                                            return (
                                                <div className="d-flex align-items-center mb-6" key={j}>
                                                    <SmallSvg />
                                                    <div className="d-flex align-items-center flex-wrap w-100">
                                                        <div className="mb-1 pe-3 flex-grow-1">
                                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">{m.item_name}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{m.item_count}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="d-flex align-items-center mb-6">

                                            <Alert severity="info"  >No Order Information</Alert>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-xxl-8 mb-5 mb-xl-10">
                    <div className="card card-flush h-xl-100">
                        <div className="card-header py-7">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 ">
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                        href="#latest_order">Latest Order</a>
                                </li>
                            </ul>
                            <div className="card-toolbar">
                                <Link to="/point-of-sale/order" className="btn btn-primary">Add Order</Link>
                            </div>
                        </div>
                        <div className="card-body pt-1 tab-content">
                            <div className="separator separator-dashed my-5" />
                            <div className="tab-pane fade show active" id="latest_booking">
                                {
                                    stats.last_ten_orders.length > 0 ?
                                        <table className="table table-row-dashed table-hover align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                                <tr>
                                                    <th>ORDER ID</th>
                                                    <th>ORDER TYPE</th>
                                                    <th>GUEST NAME</th>
                                                    <th>AMOUNT</th>
                                                    <th>ORDER STATUS</th>
                                                    <th>DELIVERY </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stats.last_ten_orders.map((r, index) => {
                                                        const _class =
                                                            r.order_status === "Ordered" ? "badge-primary" :
                                                                r.order_status === "Processing" ? "badge-info" :
                                                                    r.order_status === "Delivered" ? "badge-success" : "badge-danger";
                                                        const order_items = stats.last_ten_order_details?.filter(x => x.order_id === r.order_id);
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="min-w-175px">
                                                                        <HtmlTooltip
                                                                            title={
                                                                                <React.Fragment>
                                                                                    {OrderItems(order_items)}
                                                                                </React.Fragment>
                                                                            }
                                                                        >
                                                                            <div className="position-relative ps-6 pe-3 py-2">
                                                                                <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info" />
                                                                                <Link
                                                                                    to={`/point-of-sale/latest-order`}
                                                                                    className="mb-1 text-dark text-hover-primary fw-bold">
                                                                                    Order #{r.order_id}
                                                                                </Link>
                                                                                <div className="fs-7 text-muted fw-bold">
                                                                                    Ordered at {formatDateAndTime(r.inserted_date, 'date_and_time')}
                                                                                </div>
                                                                            </div>
                                                                        </HtmlTooltip>
                                                                    </td>
                                                                    <td><div className="d-flex gap-2 mb-2">{r.order_type}</div></td>
                                                                    <td><div className="d-flex gap-2 mb-2">{r.guest_name === "No Name" ? "--" : r.guest_name}</div></td>
                                                                    <td><div className="d-flex gap-2 mb-2">{currencyConverter(r.total_after_discount)}</div></td>
                                                                    <td>
                                                                        <span className={`badge ${_class}`}>
                                                                            {r.order_status}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {r.delivery_option}
                                                                        </span>
                                                                    </td>


                                                                </tr>
                                                            )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                        : <Alert severity="info">No Latest Booking</Alert>
                                }
                            </div>
                        </div>
                    </div>
                </div>



            </div >

            <div className="row g-5 g-xl-12 pt-5">
                {
                    MonthlyData.length > 1 &&
                    <div className="col-xl-8 p-5">
                        <Chart
                            chartType="Bar"
                            data={MonthlyData}
                            options={{ chart: { title: `Monthly Sales for ${formatDateAndTime(currentDate, "year_only")}` } }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    byPaymentMethod.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={byPaymentMethod}
                            options={{ title: "Revenue by Payment Method", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

            </div>


        </>
    )
}

const SmallSvg = () => {
    return (
        <div className="symbol symbol-45px w-40px me-5">
            <span className="symbol-label bg-lighten">
                <span className="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="2" y="2" width="9" height="9" rx="2"
                            fill="currentColor" />
                        <rect opacity="0.3" x="13" y="2" width="9"
                            height="9" rx="2" fill="currentColor" />
                        <rect opacity="0.3" x="13" y="13" width="9"
                            height="9" rx="2" fill="currentColor" />
                        <rect opacity="0.3" x="2" y="13" width="9"
                            height="9" rx="2" fill="currentColor" />
                    </svg>
                </span>
            </span>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardPOS);
