import React, {useState} from "react";
import {connect} from "react-redux";
import GeneralToolbar from "../../../component/include/general-toolbar";
import DateRangePicker from "react-bootstrap-daterangepicker";

function RoomTypeReport() {
    const handleEvent = (event, picker) => {
        console.log(picker.startDate);
    }

    return (
        <>
            <GeneralToolbar title="Room Type Report" pages={[{name: 'Dashboard', link: '/'}, {name: 'Report', link: '/'}, {name: 'Room Type Report', link: '/report/room-type-report'}]} />
            <div className="row g-5 g-xl-10">
                {/*begin::Col*/}
                <div className="col-xl-12 mb-xl-10 card-rounded">
                    <DateRangePicker  onEvent={handleEvent}>
                        <input type="text" className="form-control" />
                    </DateRangePicker>
                </div>
            </div>
        </>
    )

}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(RoomTypeReport);
