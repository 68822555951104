import React from 'react'
import { formatDate } from '../../../context/function'
import Select2 from "react-select2-wrapper";
// import "react-select2-wrapper/css/select2.css";
import Select from "react-select";


export default function AllowancesAnddeductionsForm(props) {
    return (
        <div>
            <form onSubmit={props.onSubmit}>

                <div className="row">
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="employee_id">Employee</label>
                            {/* <Select2
                                id="employee_id"
                                multiple
                                defaultValue={props.values.selected_employees}
                                data={props.employees}
                                onChange={props.onMultiEdit}
                                options={{
                                    placeholder: "Search employee",
                                }}
                            /> */}

                            {
                                props.values.allowance_id === "" ?
                                    <Select
                                        isMulti={true}
                                        name="employee_ids"
                                        // value={props.values.selected_employees}
                                        onChange={props.onMultiEdit}
                                        options={props.employees}
                                        placeholder="select employees"
                                    /> :
                                    <select className='form-control' id="employee_id"
                                        onChange={props.onChange} value={props.values.employee_id} required  >
                                        <option value={""}> -select employee- </option>
                                        {
                                            props.employeeList?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.employee_id}> {x.full_name} -- {x.employee_id.toString().padStart(4, "0")} </option>
                                                )
                                            })
                                        }
                                    </select>
                            }
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="post_type"> Posting Type </label>
                            <select className='form-control' id="post_type" onChange={props.onChange} value={props.values.post_type} required  >
                                <option value={""}> -select account type- </option>
                                <option value={"Allowance"}>Allowance</option>
                                <option value={"Deduction"}>Deduction</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 pb-3 ">
                        <div className="form-group">
                            <label htmlFor="ledger_account">Allowance/Deduction</label>
                            <select className='form-control' id="ledger_account"
                                onChange={props.onChange} value={props.values.ledger_account} required  >
                                <option value={""}> -select A\D- </option>
                                {
                                    props.ledgerAccountsList?.map((x, i) => {
                                        return (
                                            <option key={i} value={x.ledger_id}> {x.description} -- {x.ledger_id.toString().padStart(4, "0")} </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="frequency">Frequency </label>
                            <select className='form-control' id="frequency" onChange={props.onChange} value={props.values.frequency} required  >
                                <option value={""}> -select frequency- </option>
                                <option value={"Once"}>Once</option>
                                <option value={"Monthly"}>Monthly</option>
                                <option value={"Quaterly"}>Quaterly</option>
                                <option value={"Annually"}>Annually</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="start_date">Start Date</label>
                            <input type="month" id="start_date" className="form-control" value={props.values.start_date} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="end_date">End Date</label>
                            <input type="month" id="end_date"
                                disabled={props.values.start_date === ""}
                                min={props.values.start_date}
                                className="form-control" value={props.values.end_date} onChange={props.onChange} required />
                        </div>
                    </div>


                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="status">Status </label>
                            <select className='form-control' id="status" onChange={props.onChange} value={props.values.status} required  >
                                <option value={""}> -select status- </option>
                                <option value={"Active"}>Active</option>
                                <option value={"Inactive"}>Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="amount">Amount(N)</label>
                            <input type="number" step={"any"} min="0" id="amount"
                                className="form-control" value={props.values.amount} onChange={props.onChange} required />
                        </div>
                    </div>


                    <button type="submit" className="btn btn-lg btn-block btn-primary mt-5" disabled={props.isSubmitting} >
                        {props.isSubmitting ? <span> <i className='fa fa-spin fa-spinner' /> &nbsp;Please wait...</span> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}
