import React from "react";
import './menu.css'
export default function GeneralMainMenu(props) {


    return (
        <div className="align-self-end overflow-auto" id="kt_brand_tabs">
            <div className="header-tabs overflow-auto mx-4 ms-lg-10 mb-5 mb-lg-0" id="kt_header_tabs">
                {/*begin::Header tabs*/}
                <ul className="nav flex-nowrap text-nowrap">
                    {
                        props.menuList.length > 0 &&
                        props.menuList.map((menu, i) => {
                            return <li key={i} className="nav-item">
                                <a className={`nav-link ${i === 0 ?? 'active'}`} data-bs-toggle="tab" href={`#menu-${menu.menu_id}`}>{menu.menu_name}</a>
                            </li>
                        })
                    }
                </ul>
                {/*begin::Header tabs*/}
            </div>
        </div>
    )
};