import axios from 'axios';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import {formatDateAndTime, showConfirm} from '../../../context/function';

function AdminSalarySettings(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const columns = ["S/N", "Salary Month", "Inserted By", "Date Inserted", "ACTION"];

    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getSalaryReport(val)
    }

    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {

        } else {
            setIsLoading(true)
            await axios.get(`${API_URL}report/salary/settings/${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        let rows = [];
                        res.data.map((x, i) => {
                            rows.push([
                                i + 1,
                                formatDateAndTime(x.salary_date, "month_and_year"),
                                x.employee_name,
                                formatDateAndTime(x.inserted_date, "date"),
                                <div className='d-flex'>
                                    < button
                                        className='btn btn-sm btn-danger'
                                        onClick={()=>showConfirm("Warning", `Are you sure you want to reset salary run for ${formatDateAndTime(x.salary_date, "month_and_year")} ?`, "warning")
                                            .then( async (confirm) => {
                                                if (confirm) {
                                                    setIsLoading(true)
                                                    await axios.delete(`${API_URL}human-resources/payroll/delete/${x.salary_date}`, login?.token).then((results) => {
                                                        if (results.data.message === "success"){
                                                            toast.success("Salary Deleted Successfully");
                                                            getSalaryReport(x.salary_date)
                                                        }else{
                                                            setIsLoading(false)
                                                            toast.error("Salary not deleted");
                                                        }
                                                    })
                                                }
                                            })}
                                    >

                                        <i className='fa fa-trash-alt' />
                                        &nbsp; { "Reset Salary Run"}
                                    </button >
                                </div>
                            ])
                        })
                        setTableData(rows)
                    }else{
                        toast.error("Not record found")
                        setTableData([])
                    }
                    setIsLoading(false)
                }).catch((e) => {
                    setIsLoading(false)
                    toast.error("error getting allowances")
                })
        }
    }


    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Salary Settings"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Settings', link: '/' },
                                    { name: 'Salary Settings', link: '/settings/admin-salary-settings' }]}
                                // button={<button className="btn btn-primary" onClick={() => { onOpenModal(true) }}>+ Add Department</button>}
                />

                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="month_id">Select Month</label>
                        <input type="month" id="month_id"
                               className="form-control"
                               value={formData.month_id}
                               onChange={onChange}
                               max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                               required />
                    </div>
                </div>
                {
                    isLoading ?
                        <Loader />
                        :
                        <ReportTable title={"Salary Settings"} columns={columns} data={tableData} height={"700px"} />
                }


            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(AdminSalarySettings);
