import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import { API_URL } from "../../../context/connection-string";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DashboardPageTitle } from "./dashboard-page-title";
import Chart from "react-google-charts";

function DashboardHouseKeeping({ loginData }) {
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState({
        maintenance_request: 0, cleaning_request: 0, cleaning_schedule: 0, maintenance_approve: 0, maintenance_pending: 0, maintenance_cancel: 0, cleaning_schedule_complete: 0, cleaning_schedule_incomplete: 0, cleaning_request_request: 0, cleaning_request_complete: 0, cleaning_request_incomplete: 0,
    })
    const [maintenanceRequestData, setMaintenanceRequestData] = useState([["Maintenance Status", "Count"]])
    const [cleaningScheduleData, setcleaningScheduleData] = useState([["Cleaning Schedule Status", "Count"]])
    const [cleaningRequestData, setcleaningRequestData] = useState([["Cleaning Request Status", "Count"]])


    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/house-keeping-dashboard/${loginData[0].branch_id}`, loginData[0].token)
            .then(res => {
                //MAINTENANCE CHART START
                const maintenance = res.data.maintenance_list;
                let maintenance_data = [
                    ...maintenanceRequestData
                ]
                if (maintenance.length > 0) {
                    const unique_maintenance_status = [...new Set(maintenance.map(item => item.status))];
                    unique_maintenance_status.map(r => {
                        return maintenance_data.push([r, maintenance.filter(e => e.status === r).length])
                    })
                }
                setMaintenanceRequestData(maintenance_data)
                //MAINTENANCE CHART END

                //CLEANING SCHEDULE CHART START
                const cleaning_schd = res.data.cleaning_schedule_list;
                let cleaning_schedule_data = [
                    ...cleaningScheduleData
                ]
                if (cleaning_schd.length > 0) {
                    const unique_cleaning_status = [...new Set(cleaning_schd.map(item => item.status))];
                    unique_cleaning_status.map(r => {
                        return cleaning_schedule_data.push([r, cleaning_schd.filter(e => e.status === r).length])
                    })
                }
                setcleaningScheduleData(cleaning_schedule_data)
                //CLEANING SCHEDULE  CHART END

                //CLEANING SCHEDULE CHART START
                const cleaning_request = res.data.cleaning_request_list;
                let cleaning_request_data = [
                    ...cleaningRequestData
                ]
                if (cleaning_request.length > 0) {
                    const unique_cleaning_request_status = [...new Set(cleaning_request.map(item => item.request_status))];
                    unique_cleaning_request_status.map(r => {
                        return cleaning_request_data.push([r, cleaning_request.filter(e => e.request_status === r).length])
                    })
                }
                setcleaningRequestData(cleaning_request_data)
                //CLEANING SCHEDULE  CHART END

                setStats({
                    maintenance_request: res.data.maintenance_list.length,
                    maintenance_approve: res.data.maintenance_list.filter(x => x.status === 'Approved').length,
                    maintenance_pending: res.data.maintenance_list.filter(x => x.status === 'Pending').length,
                    maintenance_cancel: res.data.maintenance_list.filter(x => x.status === 'Cancelled').length,

                    cleaning_schedule: res.data.cleaning_schedule_list.length,
                    cleaning_schedule_complete: res.data.cleaning_schedule_list.filter(x => x.status === 'Completed').length,
                    cleaning_schedule_incomplete: res.data.cleaning_schedule_list.filter(x => x.status === 'Incompleted').length,

                    cleaning_request: res.data.cleaning_request_list.length,
                    cleaning_request_request: res.data.cleaning_request_list.filter(x => x.request_status === 'Assigned to Staff').length,
                    cleaning_request_complete: res.data.cleaning_request_list.filter(x => x.request_status === 'Completed').length,
                    cleaning_request_incomplete: res.data.cleaning_request_list.filter(x => x.request_status === 'Incompleted').length,

                })
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useState(() => {
        fetchRecord()
    }, [])

    return isLoading ? <Loader /> : (
        <>
            <DashboardPageTitle title="House Keeping" />
            <div className="row g-5 g-lg-12 pt-5">
                <div className="col-xxl-4 col-md-4 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">
                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-dark">
                                <div className="d-flex flex-stack">
                                    <h3 className="m-0 text-white fw-bold fs-3">Maintenance Request</h3>
                                </div>
                                <div className="d-flex text-center flex-column text-white pt-8">
                                    <span className="fw-semibold fs-7">Maintenance Request</span>
                                    <span className="fw-bold fs-2x pt-1">{stats.maintenance_request}</span>
                                </div>
                            </div>
                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: '-100px' }}>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Approved</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.maintenance_approve}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Pending</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.maintenance_pending}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Cancelled</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.maintenance_cancel}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">
                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-dark">
                                <div className="d-flex flex-stack">
                                    <h3 className="m-0 text-white fw-bold fs-3">Cleaning Schedule</h3>
                                </div>
                                <div className="d-flex text-center flex-column text-white pt-8">
                                    <span className="fw-semibold fs-7">Cleaning Schedule</span>
                                    <span className="fw-bold fs-2x pt-1">{stats.cleaning_schedule}</span>
                                </div>
                            </div>
                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: '-100px' }}>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Complete</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.cleaning_schedule_complete}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Incomplete</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.cleaning_schedule_incomplete}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-4 col-md-4 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">
                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-dark">
                                <div className="row">
                                <div className="d-flex flex-stack col-md-7">
                                    <h3 className="m-0 text-white fw-bold fs-3">Cleaning Request</h3>
                                </div>
                                <div className="card-toolbar col-md-5">
                                <Link to="/house-keeping/cleaning-schedule" className="btn btn-primary">Add Cleaning Schedule</Link>
                            </div>
                                </div>
                                
                                <div className="d-flex text-center flex-column text-white pt-8">
                                    <span className="fw-semibold fs-7">Cleaning Request</span>
                                    <span className="fw-bold fs-2x pt-1">{stats.cleaning_request}</span>
                                </div>
                            </div>
                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{ marginTop: '-100px' }}>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Assigned to Staff</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.cleaning_request_request}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Complete</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.cleaning_request_complete}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                        fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                        height="9" rx="2" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Incomplete</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.cleaning_request_incomplete}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {
                    maintenanceRequestData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={maintenanceRequestData}
                            options={{ title: "Maintenance Request Status", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    cleaningScheduleData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={cleaningScheduleData}
                            options={{ title: "Cleaning Schedule Status", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

{
                    cleaningRequestData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={cleaningRequestData}
                            options={{ title: "Cleaning Request Status", is3D: true }}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }
            </div>



        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardHouseKeeping);
