import React from 'react'

export default function PensionForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="pension_name">PFA Name</label>
                        <input type="text" id="pension_name" className="form-control" value={props.values.pension_name} onChange={props.onChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="account_name">PFC Name</label>
                        <input type="text" id="account_name" className="form-control" value={props.values.account_name} onChange={props.onChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="account_number">PFC Account No.</label>
                        <input type="text" id="account_number" className="form-control" value={props.values.account_number} onChange={props.onChange} required />
                    </div>
                </div>
                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
