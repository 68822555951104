import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { connect } from 'react-redux';
import { createRoutesFromElements } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../../component/include/general-toolbar';
import Loader from '../../../../component/loader/loader';
import { API_URL } from '../../../../context/connection-string';
import ReportTable from '../../../../component/table/report-table';
import BootstrapModal from '../../../../component/modal/bootstrap-modal';
import { currencyConverter } from '../../../../context/function';
import { formatDate, formatDateAndTime } from '../../../../context/function';
import PayrollSidebar from "../../../../component/payroll-sidebar/payroll-sidebar";

function PayRegsiter(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const columns = ["S/N", "EMPLOYEE ID", "EMPLOYEE", "SALARY DATE", "ACCOUNT NUMBER", "BANK", "SORT CODE", "GROSS PAY", "DEDUCTIONS", "NET PAY",]
    const componentRef = useRef();


    const [salaryMonths, setSalaryMonths] = useState([])

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setSalaryMonths(res.data)
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }

    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {
            setTableData([])
        } else {
            setIsSubmitting(true)
            const { data } = await axios.get(`${API_URL}human-resources/report/salary/details/${salary_month}`, login?.token);
            // let gross_pay = data.map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0);

            await axios.get(`${API_URL}human-resources/report/salary/summary?salary_month=${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        let rows = [];
                        const gross_pay = currencyConverter(data.filter(x => x.item_name === "Gross Pay")?.map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0))
                        const net_pay = currencyConverter(data.filter(x => x.item_name === "Net Pay")?.map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0))
                        const sum_deductions = currencyConverter(data.filter(x => x.item_name === "Total Deduction")?.map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0))

                        // let total_deductions = data.filter(j => j.salary_type === "Deduction").map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0);
                        // let total_net = data.filter(j => j.salary_type === "Allowance").map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0);
                        //
                        res.data.map((x, i) => {
                            const allowances = data.filter(j => j.salary_type === "Allowance" && j.employee_id === x.employee_id);
                            const deductions = data.filter(j => j.salary_type === "Deduction" && j.employee_id === x.employee_id);

                           
                            const acct = data.filter(j => j.employee_id === x.employee_id);
                            rows.push([
                                i + 1,
                                x.employee_id.toString().padStart(4, "0"),
                                x.employee_name + ` (${x.employee_id})`,
                                formatDateAndTime(x.salary_date, "month_and_year"),
                                acct[0]?.account_number,
                                acct[0]?.bank_name,
                                acct[0]?.sort_code,
                                currencyConverter(x.total_pay),
                                currencyConverter(deductions?.map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0)),
                                currencyConverter(allowances?.map(e => parseInt(e.amount)).reduce((a, b) => a + b, 0)),
                            ])
                        })
                        const totals = [
                            `--`,
                            `--`,
                            `--`,
                            `--`,
                            `--`,
                            `--`,
                            <b><h5>TOTAL</h5></b>,
                            (<b>{gross_pay}</b>),
                            <b>{sum_deductions}</b>,
                            <b>{net_pay}</b>,
                        ]
                        setTableData([...rows, totals])
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getSalaryReport(val)
    }

    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Pay Register"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/human-resources/pay-register' },
                    { name: 'Pay Register', link: '/human-resources/pay-register' }]}
                />

                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <div className='container mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <select className='form-control' id="month_id" onChange={onChange} value={formData.month_id} required  >
                                                <option value={""}> -select month- </option>
                                                {
                                                    salaryMonths.length > 0 &&
                                                    salaryMonths.map((x, i) => {
                                                        return (
                                                            <option value={x.salary_date} key={i}> {formatDateAndTime(x.salary_date, "month_and_year")} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <ReportTable title={"Pay Register"} columns={columns} data={tableData} height={"700px"} />
                        }                    </div>
                </div>
            </>
          

        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(PayRegsiter);
