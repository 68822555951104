import React from 'react';
import {Link} from "react-router-dom";

export default function GeneralToolbar({title='Page Title', pages=[], button}) {
    return (
        <div className="toolbar mb-n1 pt-3 pb-6 mb-lg-n3 pt-lg-6 d-print-none" id="kt_toolbar">
            {/*begin::Container*/}
            <div id="kt_toolbar_container" className="col-md-12 d-flex flex-stack flex-wrap">
                {/*begin::Page title*/}
                <div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2">
                    {/*begin::Title*/}
                    <h1 className="d-flex text-dark fw-bold m-0 fs-3">{title}</h1>
                    {/*end::Title*/}
                    {/*begin::Breadcrumb*/}
                    <ul className="breadcrumb breadcrumb-dot fw-semibold text-gray-600 fs-7">
                        {
                           pages.length > 0 &&
                               pages.map((page, index) => {
                                   return <li key={index} className="breadcrumb-item text-gray-600">
                                       <Link to={page.link} className="text-gray-600 text-hover-primary">
                                           {page.name}
                                       </Link>
                                   </li>
                               })
                        }

                    </ul>
                    {/*end::Breadcrumb*/}
                </div>
                {/*end::Page title*/}

                <div className="d-flex align-items-center">
                    {button}
                </div>

            </div>
            {/*end::Container*/}
        </div>

    )
}
