import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../module/authentication/login";
import ForgotPassword from "../../module/authentication/forgot-password";
import ResetPassword from "../../module/authentication/reset-password";

export default function PublicRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:slug" element={<ResetPassword/>} />
            <Route path="*" element={<Login />} />
        </Routes>
    );
}