import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import PaySlipPrint from './pay-slip/pay-slip';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";
import Select from "react-select";
import "./style.css"

function MultiplePaySlip(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState([]);
    const [reportDate, setReportDate] = useState([]);
    const [insertedDate, setInsertedDate] = useState([]);
    const [staff, setStaff] = useState([]);
    const componentRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        month_id: "",
        start_date: "",
        end_date: "",
        staff: "",
        staff2: "",
        employee_id: "",
        employee_name: "",
        salary_date: "",
        run_date: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {

        await axios.get(`${API_URL}report/select/data`, login?.token).then((res) => {
            if (res.data.message === "success"){
                let staffRows = [];
                res.data.Staff.map((row) => {
                    staffRows.push({value: row.employee_id, label: row.employee_name})
                });
                setStaff(prevState => [prevState, ...staffRows])
            }else{
                setStaff([])
            }
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }
    const getSalaryReport = async (staff, start_date, end_date) => {
            setIsSubmitting(true)
            await axios.get(`${API_URL}human-resources/multiple/salary/summary?staff=${staff}&start_date=${start_date}&end_date=${end_date}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        let distinctEmployeeID = [...new Set(res.data.map(e=> e.employee_id))];
                        let distinctEmployeeNO = [...new Set(res.data.map(e=> e.staff_id))];
                        let distinctEmployeeName = [...new Set(res.data.map(e=> e.EmployeeName))];
                        let distinctDate = [...new Set(res.data.map(e=> e.salary_date))];
                        let inserted_date = [...new Set(res.data.map(e=> e.inserted_date))];
                        setFormData({
                            ...formData,
                            employee_name: distinctEmployeeName.length > 0 ? distinctEmployeeName[0] : "",
                            employee_id: distinctEmployeeID.length > 0 ? distinctEmployeeID[0] : "",
                            staff_id: distinctEmployeeNO.length > 0 ? distinctEmployeeNO[0] : "",
                        })
                        setReportDate(distinctDate)
                        setInsertedDate(inserted_date)
                        setReport(res.data)

                    }else{
                        setReport([])
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })

    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        if (val !== "" && formData.end_date !== "" && formData.staff !== ""){
            getSalaryReport(formData.staff, val, formData.end_date)
        }
    }

    const onChange2 = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        if (formData.start_date !== "" && val !== "" && formData.staff !== ""){
            getSalaryReport(formData.staff, formData.start_date,  val)
        }
    }


    const onChangeStaff = (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            staff: val,
            staff2: e,
        })
        if (formData.start_date !== "" && formData.end_date !== "" && val!== ""){
            getSalaryReport(val, formData.start_date, formData.end_date)
        }
    }



    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Salary Report"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/human-resources/payroll' },
                                    { name: 'Salary Report', link: '/human-resources/salary-report' }]}
                />

                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5 no-printing'>
                            <div className='card card-body'>
                                <div className="row">
                                        <div className="col-md-4 pb-3">
                                            <div className="form-group ">
                                                <label htmlFor="staff">Select Staff</label>
                                                <Select
                                                    id="staff"
                                                    name="staff"
                                                    value={formData.staff2}
                                                    onChange={onChangeStaff}
                                                    options={staff}
                                                    placeholder="Select Staff"
                                                />
                                            </div>
                                        </div>

                                    <div className="col-md-4 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="start_date">Select Start Date</label>
                                            <input type="month" id="start_date"
                                                   className="form-control"
                                                   value={formData.start_date}
                                                   onChange={onChange}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>

                                    <div className="col-md-4 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="end_date">Select End Date</label>
                                            <input type="month" id="end_date"
                                                   className="form-control"
                                                   value={formData.end_date}
                                                   onChange={onChange2}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div componentRef={componentRef}>
                                    <div className="col-md-12 d-block"><button
                                        style={{float: 'right'}}
                                        className='btn btn-md btn-primary no-printing mb-3'
                                        onClick={() => window.print()} >
                                        + Print Pay Slip
                                    </button>
                                        <br className="no-printing" /> <br className="no-printing" /> <br className="no-printing" />
                                    </div>

                                    {
                                        reportDate.length > 0 ? reportDate.map((val, int)=> {
                                            return <div key={int} className={int < 1 ? `margin-up break-after col-md-12` : ` break-after col-md-12`}>
                                                <PaySlipPrint
                                                    data={{ salary: report.filter(e=>e.salary_date === val), ...formData, run_date: insertedDate[int],  salary_date: val }}
                                                /><br/>
                                            </div>
                                        })
                                            :
                                            <div className={`break-after col-md-12 alert alert-danger text-center`}>
                                                <h3>No Record Found!</h3>
                                            </div>
                                    }
                                </div>

                        }
                    </div>
                </div>
            </>

        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(MultiplePaySlip);
