import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../context/connection-string';
import { currencyConverter } from '../../../../context/function';

function EmployeeEmoluments(props) {
    const emolumentSettings = props.emolumentSettings;
    const employeeEmoluments = props.employeeEmoluments;
    const formData = props.formData;
    const setFormData = props.setFormData;



    const onIncomeSettingChange = (e, index, data) => {
        const new_amount = parseFloat(e.target.value);
        if (employeeEmoluments.length > 0) {
            const objIndex = employeeEmoluments.findIndex((x => parseInt(x.settings_item_id) === parseInt(data.item_id)));
            if (objIndex > -1) {
                employeeEmoluments[objIndex].amount = new_amount;
                props.setemployeeEmoluments(employeeEmoluments);

                //cause ui update
                props.setEmolumentsSettings([...emolumentSettings])
            }

            const taxable_total = employeeEmoluments.filter(x => x.is_taxable === 1).map(x => parseFloat(x.amount)).reduce((a, b) => a + b, 0);
            setFormData({
                ...formData,
                taxable_total: taxable_total
            })
        }
    }

    const onNonTaxableIncomeSettingChange = (e, index, data) => {
        const percentage = parseFloat(e.target.value);

        if (employeeEmoluments.length > 0) {
            const objIndex = employeeEmoluments.findIndex((x => parseInt(x.settings_item_id) === parseInt(data.item_id)));
            if (objIndex > -1) {
                if (data.category === "pension") {
                    const percent_value = (8 / 100) * (parseFloat(formData.basic_salary) + parseFloat(formData.transport_salary) + parseFloat(formData.housing_salary));
                    employeeEmoluments[objIndex].amount = percent_value.toFixed(2);
                    props.setemployeeEmoluments(employeeEmoluments);

                    //cause ui update
                    props.setEmolumentsSettings([...emolumentSettings])
                } else {

                    const percent_value = (percentage / 100) * (parseFloat(formData.basic_salary));
                    employeeEmoluments[objIndex].amount = percent_value.toFixed(2);
                    props.setemployeeEmoluments(employeeEmoluments);

                    //cause ui update
                    props.setEmolumentsSettings([...emolumentSettings])
                }
            }
            else {
               
                // const percent_value = (percentage / 100) * (parseFloat(formData.basic_salary))
                // const dt = {
                //     ...employeeEmoluments[0],
                //     item_name: data.item_name,
                //     settings_item_id: data.item_id,
                //     amount : percent_value
                // }

                // props.setemployeeEmoluments({
                //     ...employeeEmoluments,
                //     dt
                // })
                
                // props.setEmolumentsSettings([...emolumentSettings])
            }

            const non_taxable_total = employeeEmoluments.filter(x => x.is_taxable === 0).map(x => parseFloat(x.amount)).reduce((a, b) => a + b, 0);
            setFormData({
                ...formData,
                non_taxable_total: non_taxable_total
            })
        }

    }

    const onSubmitIncome = async () => {
        let items = document.getElementsByClassName("amount");
        let items_array = [];
        let nt_items_array = [];
        let to_be_deleted = [];

        for (let i = 0; i < items.length; i++) {
            const element = items[i];
            const item_id = element.getAttribute("item_id");
            const item_name = element.getAttribute("item_name");
            const item_value = element.value;

            items_array.push({
                item_id: item_id,
                item_name: item_name,
                item_value: item_value
            })
        }


        const non_taxable = await employeeEmoluments.filter(x => x.is_taxable === 0)
        for (let i = 0; i < non_taxable.length; i++) {
            const element = non_taxable[i];
            if (parseInt(element.amount) !== 0) {
                nt_items_array.push({
                    item_id: element.settings_item_id,
                    item_name: element.item_name,
                    item_value: element.amount
                })
            } else {
                to_be_deleted.push({
                    item_id: element.settings_item_id,
                    item_name: element.item_name,
                    item_value: element.amount
                })
            }
        }

        const body = {
            items_array: items_array.concat(nt_items_array),
            employee_id: props.employee_id,
            inserted_by: props.inserted_by,
            to_be_deleted: to_be_deleted,
        }


        try {
            await axios.post(`${API_URL}human-resources/employee-income/settings/add`, body).then((res) => {
                if (res.data.message === "success") {
                    toast.success(`income and emoluments settings added for ${props.employee_id}`)
                } else {
                    toast.error("an error occured")
                }

            })
        } catch (e) {
            toast.error("error occured")
        }

    }

    const NonTaxable = ({ emolumentSettings, employeeEmoluments }) => {
        return (
            <>
                {
                    emolumentSettings.length > 0 &&
                    emolumentSettings.filter(q => parseInt(q.is_taxable) === 0).map((x, i) => {

                        const amount = employeeEmoluments?.filter(j => parseInt(j.settings_item_id) === parseInt(x.item_id))
                        const amount_value = amount.length > 0 ? amount[0].amount : 0;
                        const default_percentage = amount.length > 0 ? (parseFloat(amount[0]?.amount) * 100) / (parseFloat(formData.basic_salary)) : 0;
                        const default_percentage_value = x.category === "pension" ? 8 : default_percentage?.toFixed(2)

                        return (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{x.item_name}</td>
                                <td className='w-25'>
                                    <input
                                        disabled={x.category === "pension"}
                                        onChange={(e) => { onNonTaxableIncomeSettingChange(e, i, x) }}
                                        className='form-control non_taxable'
                                        id={`nt-input-` + i}
                                        item_name={x.item_name}
                                        item_id={x.item_id}
                                        item_amount={x.amount}
                                        key={i}
                                        min={0}

                                        type="number"
                                        name="input[]"
                                        defaultValue={default_percentage_value}
                                        index={i}
                                        placeholder="percentage"
                                    />
                                </td>
                                <td>
                                    <div className='mt-4'
                                        item_name={x.item_name}
                                        item_id={x.item_id}
                                        item_value={amount_value}
                                    >
                                        <label>
                                            {currencyConverter(amount_value)}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </>
        )
    }


    return (
        <div>
            <div className='row'>
                <div className='col-md-6'>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <td colSpan={3}><h6>Income and Emoluments</h6></td>
                            </tr>
                            <tr>
                                <td>SN</td>
                                <td>Item</td>
                                <td>Amount (Naira)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                emolumentSettings.length > 0 &&
                                emolumentSettings.filter(q => parseInt(q.is_taxable) === 1).map((x, i) => {
                                    const amount = employeeEmoluments?.filter(j => parseInt(j.settings_item_id) === parseInt(x.item_id))
                                    const amount_value = amount.length > 0 ? amount[0].amount : 0;
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{x.item_name}</td>
                                            <td>
                                                <input
                                                    onChange={(e) => { onIncomeSettingChange(e, i, x) }}
                                                    className='form-control amount'
                                                    id={`input-` + i}
                                                    item_name={x.item_name}
                                                    item_id={x.item_id}
                                                    key={i}

                                                    type="number"
                                                    name="input[]"
                                                    defaultValue={amount_value}
                                                    index={i}
                                                    placeholder="Amount"

                                                />
                                            </td>
                                            <td>
                                                {currencyConverter(amount_value)}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td></td>
                                <td colSpan={2}><b>TOTAL INCOME (TI)</b></td>
                                <td><h3>{currencyConverter(formData.taxable_total)}</h3></td>
                            </tr>

                            <tr>
                                <td colSpan={3}><h6>Non Taxable Income & Tax Exemp Items</h6></td>
                            </tr>

                            <NonTaxable emolumentSettings={emolumentSettings} employeeEmoluments={employeeEmoluments} />
                            <tr>
                                <td></td>
                                <td colSpan={2}><b>TOTAL NON-TAXABLE AND TAX EXEMPTS</b></td>
                                <td><h3>{currencyConverter(formData.non_taxable_total)}</h3></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td colSpan={2}><b>GROSS INCOME (GI) FOR CRA PURPOSE</b></td>
                                <td><h3>{currencyConverter(formData?.gross_income)}</h3></td>
                            </tr>

                            <tr>
                                <td colSpan={4}></td>
                            </tr>
                            <tr>
                                <td colSpan={4}><h6>Less : Relief</h6></td>
                            </tr>
                            <tr>
                                <td>#</td>
                                <td>Fixed Consolidated Relief Allowance (CRA) - 1% of GI OR 200,000, whichever is greater</td>
                                <td>{parseFloat(formData?.cra).toFixed(2)}</td>
                                <td>{currencyConverter(parseFloat(formData?.cra))}</td>
                            </tr>
                            <tr>
                                <td>#</td>
                                <td>Variable Consolidated Relief Allowance (VRA) - 20% of GI OR CRA, whichever is greater</td>
                                <td>{parseFloat(formData?.vra).toFixed(2)}</td>
                                <td>{currencyConverter(formData?.vra)}</td>
                            </tr>
                            <NonTaxable emolumentSettings={emolumentSettings} employeeEmoluments={employeeEmoluments} />
                            <tr>
                                <td></td>
                                <td colSpan={2}><b>TOTAL RELIEF (TR)</b></td>
                                <td><h3>{currencyConverter(formData?.total_relief)}</h3></td>
                            </tr>
                            <tr>
                                <td colSpan={4}></td>
                            </tr>

                            <tr>
                                <td>#</td>
                                <td colSpan={2}>Chargable Income TI - TR </td>
                                <td><h3>{currencyConverter(formData?.chargable_income)}</h3></td>
                            </tr>
                            <tr>
                                <td>#</td>
                                <td colSpan={2}>PAYE Tax Payable </td>
                                <td><h3>{currencyConverter(parseFloat(formData?.total_paye_bands).toFixed(2))}</h3></td>
                            </tr>
                            <tr>
                                <td>#</td>
                                <td colSpan={2}>Total Deductions (TD) </td>
                                <td><h3>{currencyConverter(formData?.total_deductions)}</h3></td>
                            </tr>

                            <tr>
                                <td colSpan={4}></td>
                            </tr>
                            <tr>
                                <td>#</td>
                                <td colSpan={2}>Net Pay (TI - TD) </td>
                                <td><h3>{currencyConverter(formData?.net_pay)}</h3></td>
                            </tr>

                            <tr>
                                <td colSpan={4}>
                                    <button className='btn btn-sm bg-dark text-white w-100' onClick={onSubmitIncome}>
                                        Submit
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-md-6' >
                    <div className='table-responsive'>

                        <table className='table table-striped' style={{ width: "100%" }}>
                            <thead>
                                <tr className='fw-bold'>
                                    <td>PAYE BANDS</td>
                                    <td>RATES</td>
                                    <td>BAND</td>
                                    <td>PAYE (test)</td>
                                    <td>PAYE (correct)</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>First N300,000</td>
                                    <td>7%</td>
                                    <td>N300,000</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[0]).toFixed(2))}</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[0]).toFixed(2))}</td>
                                </tr>
                                <tr>
                                    <td>Next N300,000</td>
                                    <td>11%</td>
                                    <td>N300,000</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[1]).toFixed(2))}</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[1]).toFixed(2))}</td>
                                </tr>
                                <tr>
                                    <td>Next N500,000</td>
                                    <td>15%</td>
                                    <td>N500,000</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[2]).toFixed(2))}</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[2]).toFixed(2))}</td>
                                </tr>
                                <tr>
                                    <td>Next N500,000</td>
                                    <td>19%</td>
                                    <td>N500,000</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[3]).toFixed(2))}</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[3]).toFixed(2))}</td>
                                </tr>
                                <tr>
                                    <td>Next N1,600,000</td>
                                    <td>21%</td>
                                    <td>N1,600,000</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[4]).toFixed(2))}</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[4]).toFixed(2))}</td>
                                </tr>
                                <tr>
                                    <td>Above N3,200,000</td>
                                    <td>24%</td>
                                    <td>N3,300,000</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[5]).toFixed(2))}</td>
                                    <td>{currencyConverter(parseFloat(formData?.paye_bands[5]).toFixed(2))}</td>
                                </tr>
                                <tr className='fw-bolder'>
                                    <td>TOTAL PAYE</td>
                                    <td colSpan={2}></td>
                                    <td>{currencyConverter(formData?.total_paye_bands)}</td>
                                    <td>{currencyConverter(formData?.total_paye_bands)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}



const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(EmployeeEmoluments);
