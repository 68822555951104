import {connect} from "react-redux";
import GeneralToolbar from "../../../component/include/general-toolbar";
import ReportTable from "../../../component/table/report-table";
import React, { useState} from "react";
import { toast } from "react-toastify";
import {API_URL} from "../../../context/connection-string";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import {currencyConverter, formatDate, formatDateAndTime} from "../../../context/function";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';

function BookingGeneralReport(props) {
    let token = props.loginData[0].token;
    let BranchID = props.loginData[0]?.branch_id.toString();
    const [isLoading, setIsLoading] = useState(false);

    const [TotalAmountExpected, setTotalAmountExpected] = useState(0);
    const [TotalAmountPaid, setTotalAmountPaid] = useState(0);
    const [TotalDiscount, setTotalDiscount] = useState(0);
    const [TotalAfterDiscount, setTotalAfterDiscount] = useState(0);
    const [TotalBalance, setTotalBalance] = useState(0);
    const [LateCheckoutExpected, setLateCheckoutExpected] = useState(0);
    const [LateCheckoutPaid, setLateCheckoutPaid] = useState(0);
    const [EarlyCheckoutFee, setEarlyCheckoutFee] = useState(0);

    const columns = ["S/N", "Booking Code", "Guest Name",  "Check In", "Check Out", "Num Of Room", "Status", "Amount Expected", "Amount Paid", "Discount", "After Discount", "Balance", "Late Checkout Expected", "Late Checkout Paid", "Early Checkout Fee"];
    const [tableData,setTableData] = useState([]);

    const fetchData = async (start_date, end_date) => {
        toast.info("Please wait...")
        document.getElementById('show_table').style.display = "none";
        document.getElementById('show_analytics').style.display = "none";
        let start = formatDate(start_date);
        let end = formatDate(end_date);
        await axios.get(`${API_URL}report/booking-report/${BranchID}/${start}/${end}`, token)
            .then(res => {
                if (res.data.message === 'success') {
                    const row = []; let amount_expected = 0; let amount_paid = 0; let discount = 0; let amount_after_discount = 0; let balance = 0; let late_check_out_expected = 0; let late_check_out_paid = 0; let early_check_out_fee = 0;
                    if (res.data.response.length > 0) {
                        res.data.response.map((r, i) => {
                            amount_expected +=+ r.amount_expected;
                            amount_paid +=+ r.amount_paid;
                            discount +=+ r.discount;
                            amount_after_discount +=+ r.amount_after_discount;
                            balance +=+ r.balance;
                            late_check_out_expected +=+ r.late_check_out_expected;
                            late_check_out_paid +=+ r.late_check_out_paid;
                            early_check_out_fee +=+ r.early_check_out_fee;
                            row.push([i+1,  r.booking_code, r.guest_name, formatDateAndTime(r.check_in_date, 'date'), formatDateAndTime(r.check_out_date, 'date'), r.num_of_room, r.status, currencyConverter(r.amount_expected), currencyConverter(r.amount_paid), currencyConverter(r.discount), currencyConverter(r.amount_after_discount), currencyConverter(r.balance), currencyConverter(r.late_check_out_expected), currencyConverter(r.late_check_out_paid), currencyConverter(r.early_check_out_fee),
                            ])
                        })
                        setTableData(row)
                        setTotalAmountExpected(amount_expected)
                        setTotalAmountPaid(amount_paid)
                        setTotalBalance(balance)
                        setTotalDiscount(discount)
                        setTotalAfterDiscount(amount_after_discount)
                        setLateCheckoutExpected(late_check_out_expected)
                        setLateCheckoutPaid(late_check_out_paid)
                        setEarlyCheckoutFee(early_check_out_fee)
                        document.getElementById('show_analytics').style.display = "block";
                    }else{
                        setTableData([])
                    }
                    document.getElementById('show_table').style.display = "block";
                } else {
                    toast.info("Something went wrong. Please try again!")
                }
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }


    return isLoading ? <Loader /> : (
        <>
            <GeneralToolbar title="Booking Report" pages={[{name: 'Dashboard', link: '/'}, {name: 'Report', link: '/'}, {name: 'Booking Report', link: '/'}]}   />
            <div className="col-md-12 pb-3">
                <DateRangePicker onCallback={fetchData} >
                    <input className="form-control"/>
                </DateRangePicker>
            </div>
            {/*begin::Table*/}
            <div id="show_table" style={{display: "none"}}>
                <div  id="show_analytics" style={{display: "none"}} >
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(TotalAmountExpected)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Total Amount Expected</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(TotalAmountPaid)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Total Amount Paid</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(TotalDiscount)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Total Discount</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(TotalAfterDiscount)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">After Discount</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(TotalBalance)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Balance</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(EarlyCheckoutFee)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Early Checkout Fee</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(LateCheckoutExpected)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Late Checkout Expected</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card bg-primary hoverable card-xl-stretch mb-3 mb-xl-3">
                                <div className="card-body">
                                    <div className="text-white fw-bold fs-2 ">{currencyConverter(LateCheckoutPaid)}</div>
                                    <div className="text-white fw-bold fs-4 mb-1 mt-1">Late Checkout Paid</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReportTable title={"Booking Report"} columns={columns} data={tableData} />
            </div>
            {/*end::Table*/}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};


export default connect(mapStateToProps, null)(BookingGeneralReport);
