import React, {useState} from 'react'
import BootstrapModal from "../../component/modal/bootstrap-modal";
import {Renewal_date, currentDate, num_of_days} from "../../context/function";
export default function RenewalPopUp() {
    const [openModal, setOpenModal] = useState(true);
    return (
        <BootstrapModal
            openModal={openModal}
            closeModal={setOpenModal}
            title="Renewal Notification">
            <div className="text-center"><img style={{width: "20%", marginBottom:"40px"}}  src={require("./img/logo.png")} />
            <h2 className="alert alert-danger">Please be inform that, your subscription expire in the next  {num_of_days(currentDate, Renewal_date)} Days.</h2></div>
        </BootstrapModal>
    )
}