import {connect} from "react-redux";
import GeneralToolbar from "../../../component/include/general-toolbar";
import ReportTable from "../../../component/table/report-table";
import BootstrapModal from "../../../component/modal/bootstrap-modal";
import {useEffect, useState} from "react";
import BranchForm from "./branch-form";
import { toast } from "react-toastify";
import {API_URL} from "../../../context/connection-string";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import {formatDateAndTime, loginValidation} from "../../../context/function";


function BranchManagement(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const initialBranchValue = {
        branch_id: '', branch_name: '', address: '', city: '',
        state: '', email_address: '', phone_number: '', manager_id: '',
        status: 1, inserted_by: props.loginData[0].employee_id, updated_by: props.loginData[0].employee_id, description: "", map_url: ""}
    const [formData, setFormData] = useState(initialBranchValue);

    const [employeeList, setEmployeeList] = useState([]);

    const columns = ["S/N", "Branch Name", "Address", "Email Address", "Phone Number", "Manager", "Status","Inserted By", "Updated By", "Action"];
    const [tableData,setTableData] = useState([]);

    const fetchBranchManagementData = async () => {
      await axios.get(`${API_URL}settings/branch/management_data`, props.loginData[0].token)
          .then(res => {
              if (res.data.message === 'success') {
                  const branches = [];
                  if (res.data.branch_list.length > 0) {
                      res.data.branch_list.map((r, i) => {
                          branches.push([i+1, r.branch_name, `${r.address}, ${r.city}, ${r.state}`, r.email_address, r.phone_number,
                               r.manager_name, r.status === 1 ? 'active' : 'inactive', `${r.inserted_name}: ${formatDateAndTime(r.inserted_date, 'date')}`, `${r.updated_name}: ${r.updated_date !== null ? formatDateAndTime(r.updated_date, 'date') : '--'}`,
                              <button className="btn btn-sm btn-primary" onClick={() => {handleOnUpdateBtnClick(r)}}><i className="fa fa-pencil" /></button>])
                      })
                      setTableData(branches)
                  }
                  setEmployeeList(res.data.employee_list)
              } else {
                  toast.info("Something went wrong. Please try again!")
              }
              setIsLoading(false)
          })
          .catch(e => {
              toast.error(e.message)
              toast.error(`${e.response.statusText}: ${e.response.data}`)
          })
    }

    const handleOnUpdateBtnClick = (r) => {
        setFormData({branch_id: r.branch_id, branch_name: r.branch_name, address: r.address, city: r.city,
            state: r.state, email_address: r.email_address === null ? '' : r.email_address, phone_number: r.phone_number, manager_id: r.manager_id,
            status: r.status, inserted_by: r.inserted_by, updated_by: r.updated_by, description: r.description === null ? "" : r.description, map_url: r.map_url});
        setOpenModal(true)
    }

    const onOpenModal = () => {
        setFormData(initialBranchValue)
        setOpenModal(true);
    }
    const handleFormValueChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id] : e.target.value
        })
    }

    const handleTextEditorEdit = (e) => {
        setFormData({
            ...formData,
            description: e
        })
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (formData.branch_name.trim() === "") {
            toast.error("Please Enter the Branch Name");
            return false;
        }
        if (formData.address.trim() === "") {
            toast.error("Please Enter the Branch Address");
            return false;
        }
        if (formData.city.trim() === "") {
            toast.error("Please Enter the Branch City");
            return false;
        }
        if (formData.state.trim() === "") {
            toast.error("Please Select the Branch State");
            return false;
        }
        if (formData.phone_number.trim() === "") {
            toast.error("Please Enter the Branch Phone Number");
            return false;
        }
        if (formData.manager_id === "") {
            toast.error("Please Select the Branch Manager");
            return false;
        }
        toast.info("Submitting, please wait...");
        if (formData.branch_id === '') {
            setFormData({
                ...formData,
                inserted_by: props.loginData[0].employee_id
            })
            await axios.post(`${API_URL}settings/branch/add`, formData, props.loginData[0].token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Branch Added Successfully");
                        setOpenModal(false);
                        fetchBranchManagementData();
                    } else if (res.data.message === 'exits') {
                        toast.error("A branch with a similar name already exist. Please check the name and try again!");
                    } else {
                        toast.error("Something went wrong while adding a new branch. Please check your network and try again!");
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                })
        } else {
            setFormData({
                ...formData,
                updated_by: props.loginData[0].employee_id
            })
            await axios.patch(`${API_URL}settings/branch/update`, formData, props.loginData[0].token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Branch Updated Successfully");
                        setOpenModal(false);
                        fetchBranchManagementData();
                    } else {
                        toast.error("Something went wrong while updating the branch. Please check your network and try again!");
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                })
        }
    }

    useEffect(() => {
        if (loginValidation(window.location.pathname, props.permissionData) !== 'permitted')
            window.location.href = '/';

        fetchBranchManagementData()
    },[])

    return isLoading ? <Loader /> : (
        <>
            <GeneralToolbar title="Branch Management" pages={[{name: 'Dashboard', link: '/'}, {name: 'Settings', link: '/'}, {name: 'Branch Management', link: '/settings/branch-management'}]} button={<button className="btn btn-primary" onClick={onOpenModal}>Add Branch</button>} />
            <BootstrapModal
                openModal={openModal}
                closeModal={setOpenModal}
                size= "xl"
                title="Manage Branch">
                <BranchForm value = {formData} onChange={handleFormValueChange} onSubmit={onFormSubmit}
                            employeeList={employeeList} editorState={formData.description}
                            onEditorData={handleTextEditorEdit}
                />
            </BootstrapModal>
            {/*begin::Table*/}
            <ReportTable title={"Branch Management"} columns={columns} data={tableData} />
            {/*end::Table*/}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails
    };
};

export default connect(mapStateToProps, null)(BranchManagement);
