import React from 'react'

export default function SalarySettingsForm(props) {
    const total_ = parseFloat(props.values.basic) + parseFloat(props.values.housing) + parseFloat(props.values.transport) + parseFloat(props.values.medical) + parseFloat(props.values.wardrobe) + parseFloat(props.values.fringe) + parseFloat(props.values.payee)
    return (
        <div>
            <div className="form-group mb-4 col-md-12 alert alert-warning">
                <h2>NOTICE!</h2>
                <p className='text-danger'>The sum of all entries from this section must be 100%</p>
                <h1>{`${total_}%`}</h1>
            </div>
            <form onSubmit={props.onSubmit}>
                <div className="row">
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="basic">Basic (Percentage)</label>
                            <input type="number" step="any" min="0" max="100" id="basic"
                                className="form-control" value={props.values.basic} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="housing">Housing (Percentage)</label>
                            <input type="number"  step="any" min="0" max="100" id="housing"
                                className="form-control" value={props.values.housing} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="transport">Transport (Percentage)</label>
                            <input type="number"  step="any" min="0" max="100" id="transport"
                                className="form-control" value={props.values.transport} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="fringe">Fringe (Percentage)</label>
                            <input type="number"  step="any" min="0" max="100" id="fringe"
                                className="form-control" value={props.values.fringe} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="medical">Medical (Percentage)</label>
                            <input type="number"  step="any" min="0" max="100" id="medical"
                                className="form-control" value={props.values.medical} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="wardrobe">Wardrobe (Percentage)</label>
                            <input type="number"  step="any" min="0" max="100" id="wardrobe"
                                className="form-control" value={props.values.wardrobe} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>

                    <hr />
                    <div className="col-md-12 pb-3">
                        <div className="form-group">
                            <label htmlFor="payee">Payee (Percentage)</label>
                            <input type="number"  step="any" min="0" max="100" id="payee"
                                className="form-control" value={props.values.payee} onChange={props.onChange} onBlur={props.onSum} required />
                        </div>
                    </div>



                    <button type="submit" className="btn btn-lg btn-block btn-primary" disabled={props.isSubmitting} >
                        {props.isSubmitting ? <span> <i className='fa fa-spin fa-spinner' /> &nbsp;Please wait...</span> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}
