import React, { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer';
import { setLoginDetails, setPermissionDetails } from "../../action/set-action-details";
import { connect } from "react-redux";
import GeneralSubMenu from "./general-sub-menu";
import GeneralMainMenu from "./general-main-menu";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { GeneralMobileHeader } from "./general-mobile-header";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/img/logoo-.png"

function GeneralHeader(props) {
    const [menuList, setMenuList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState);
    };

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    };

    const SESSION_IDLE_MINUTES = 120;
    const handleOnIdle = (event) => {
        console.log('last active', getLastActiveTime())
        signOut();
    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
    })
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    const signOut = () => {
        props.setOnLoginDetails([]);
        props.setOnPermissionDetails([]);
    };

    useEffect(() => {
        if (props.permissionData.length > 0) {
            const menu_key = "menu_name";
            const menu_list = [
                ...new Map(props.permissionData.map(menu =>
                    [menu[menu_key], menu])).values()
            ];
            setMenuList(menu_list)
        }
    }, [])

    const location = useLocation();

    return (
        <div id="kt_header" className="header d-print-none ">
            {/*begin::Header top*/}
            <div className="header-top d-flex align-items-stretch flex-grow-1">
                {/*begin::Container*/}
                <div className={`d-flex container-fluid align-items-stretch bg-dark`}>
                    {/*begin::Brand*/}
                    <div className="d-flex align-items-center align-items-lg-stretch me-5 flex-row-fluid">
                        {/*begin::Header navs toggle*/}
                        <button onClick={toggleDrawer} className="d-lg-none btn btn-icon btn-color-white bg-hover-white bg-hover-opacity-10 w-35px h-35px h-md-40px w-md-40px ms-n2 me-2" id="kt_header_navs_toggle">
                            {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor" />
                                    <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor" />
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </button>
                        {/*end::Header navs toggle*/}
                        {/*begin::Logo*/}
                        <Link to={"/"}>
                            <img alt="Logo" src={Logo} className="h-25px h-lg-80px" />
                        </Link>
                        {/*end::Logo*/}
                        {/*begin::Tabs wrapper*/}
                        <GeneralMainMenu menuList={menuList} />
                        {/*end::Tabs wrapper*/}
                    </div>
                    {/*end::Brand*/}
                    {/*begin::Topbar*/}
                    <div className="d-flex align-items-center flex-row-auto">

                        {/*begin::Theme mode*/}
                        <div className="d-flex align-items-center ms-1">
                            {/*begin::Menu toggle*/}
                            <span
                                className="fa fa-power-off text-white"
                                title={"Sign out"}
                                onClick={signOut}
                            />
                            {/*begin::Menu toggle*/}
                        </div>
                        {/*end::Theme mode*/}
                        {/*begin::User*/}
                        <div className="d-flex align-items-center ms-1" id="kt_header_user_menu_toggle">
                            {/*begin::User info*/}
                            <div className="btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                {/*begin::Name*/}
                                <Link to={"staff/profile"}>
                                    <div className="d-none d-md-flex flex-column align-items-end justify-content-center me-2 me-md-4">
                                        <span className="text-white opacity-75 fs-8 fw-semibold lh-1 mb-1">Hello,</span>
                                        <span className="text-white fs-8 fw-bold lh-1">{props.loginData[0].full_name}</span>
                                    </div>
                                </Link>
                                {/*end::Name*/}

                            </div>
                            {/*end::User info*/}
                        </div>
                        {/*end::User */}
                    </div>
                    {/*end::Topbar*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header top*/}
            {/*begin::Header navs*/}

            {!isMobile ? (
                <GeneralSubMenu />
            ) : (
                <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
                    <GeneralMobileHeader permissionData={props.permissionData} onClose={setIsOpen} />
                </Drawer>
            )}

            {/*end::Header navs*/}
        </div>

    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralHeader);
