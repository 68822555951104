import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { API_URL } from '../../../../context/connection-string';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../../component/loader/loader';
import { NoImage, currencyConverter } from '../../../../context/function';
import { connect } from 'react-redux';
import GeneralToolbar from '../../../../component/include/general-toolbar';
import EmployeesForm from '../employee-form';
import EmployeeEmoluments from './emoluments';

function EmployeeDetails(props) {
    const login = props.loginData[0];

    const { employee_id } = useParams();

    const [employeeData, setEmployeeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [departmentList, setDepartmentList] = useState([]);
    const [titlesList, setTitlesList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [emolumentSettings, setEmolumentsSettings] = useState([]);
    const [employeeEmoluments, setemployeeEmoluments] = useState([]);
    const minimun_wage = 360000;

    const [formData, setFormData] = useState({
        employee_id: "", full_name: "", email_address: "", phone_number: "", address: "", date_of_birth: "", hire_date: "", job_title_id: "", department_id: "", staff_id: "", password: "", status: "", image_path: "", salary: "", bank_name: "", account_number: "", sort_code: "", department_name: "",
        inserted_by: props.loginData[0]?.employee_id, update_passport: false, gender: "", employeeFile: "",
        taxable_total: 0, basic_salary: 0, transport_salary: 0, housing_salary: 0, non_taxable_total: 0, gross_income: 0, cra: 0, vra: 0, total_relief: 0, chargable_income: 0, total_deductions: 0, net_pay: 0, is_paye_exempt: 0,
    })


    const getEmployee = async () => {
        try {
            await axios.get(`${API_URL}human-resources/employee/meta-data`, login?.token).then((res) => {
                setDepartmentList(res.data?.departments)
                setTitlesList(res.data?.titles);
            })

            const emoluments = await axios.get(`${API_URL}human-resources/emolument/list`, login?.token)
            setEmolumentsSettings(emoluments.data)


            const emoluments_score = await axios.get(`${API_URL}human-resources/employee-income/${employee_id}`, login?.token);
            setemployeeEmoluments(emoluments_score.data)
            const taxable_total = await emoluments_score.data.filter(x => x.is_taxable === 1).map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
            const non_taxable_total = await emoluments_score.data.filter(x => x.is_taxable === 0).map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
            const basic_salary = await emoluments_score.data.filter(x => x.category === 'basic')
            const transport_salary = await emoluments_score.data.filter(x => x.category === 'transport')
            const housing_salary = await emoluments_score.data.filter(x => x.category === 'housing')
            const gross_income = parseFloat(taxable_total) + parseFloat(non_taxable_total);

            const consolidated_r_a_1 = 0.01 * gross_income;
            const consolidated_r_a_2 = 200000;
            const cra = Math.max(consolidated_r_a_1, consolidated_r_a_2);

            const variable_consolidated_r_a = 0.20 * gross_income;
            const vra = Math.max(variable_consolidated_r_a, cra);

            const total_relief = parseFloat(non_taxable_total) + cra + vra;

            //chargable income
            const chargable_income = parseFloat(taxable_total) - total_relief;

            //total deduction TD
            const deductable_items = await emoluments_score.data.filter(x => x.is_taxable === 0 && x.category !== "gratuity");
            const total_deductions = deductable_items.length > 0 ?
                deductable_items.map((x => parseFloat(x.amount))).reduce((a, b) => a + b, 0) : 0;

            //net pay = ti - td
            const net_pay = parseFloat(taxable_total) - parseFloat(total_deductions);
            const min_tax = 0.01 * taxable_total;
            const is_paye_exempt = min_tax < minimun_wage ? true : false;

            let paye_bands = [];
            let total_paye_bands = 0;
            if (is_paye_exempt === true) {
                const total_amount = parseFloat(taxable_total);
                const amounts_to_cut = [300000, 300000, 500000, 500000, 1600000, 3200000]
                const percentages = [7, 11, 15, 19, 21, 24];

                paye_bands = splitAmount(total_amount, amounts_to_cut, percentages);
                console.log(paye_bands)
                total_paye_bands = paye_bands?.map((x) => { return parseFloat(x) }).reduce((a, b) => a + b, 0);
            }




            const { data } = await axios.get(`${API_URL}human-resources/employee/single/${employee_id}`, login?.token)
            setEmployeeData(data)

            setFormData({
                ...formData,
                ...data[0],
                taxable_total: taxable_total,
                basic_salary: basic_salary.length > 0 ? basic_salary[0]?.amount : 0,
                transport_salary: transport_salary.length > 0 ? transport_salary[0].amount : 0,
                housing_salary: housing_salary.length > 0 ? housing_salary[0].amount : 0,
                non_taxable_total: non_taxable_total,
                gross_income: gross_income,
                cra: cra,
                vra: vra,
                total_relief: total_relief,
                chargable_income: chargable_income,
                total_deductions: parseFloat(total_deductions),
                net_pay: net_pay,
                is_paye_exempt: is_paye_exempt,
                paye_bands: paye_bands,
                total_paye_bands: total_paye_bands,

            })

            console.log(formData)
        } catch (e) {
            toast.error("error fetching data")
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }


    function splitAmount(total_amount, amounts_to_cut, percentages) {
        let totalAmount = total_amount;
        let splits = [];

        for (let i = 0; i < amounts_to_cut.length; i++) {
            const percent = percentages[i];
            const amount = amounts_to_cut[i];

            //check if you are at the last loop first, perform last action with 24% else perform normal action for others.
            if (i === amounts_to_cut.length - 1) {
                if (totalAmount > 3200000) {
                    const splitAmount = (24 / 100) * totalAmount;
                    splits.push(splitAmount);
                    totalAmount = totalAmount;
                } else {
                    splits.push(0);
                    totalAmount -= amount;
                }
            }
            else {
                if (totalAmount > amount) {
                    const splitAmount = (percent / 100) * amount;
                    splits.push(splitAmount);
                    totalAmount -= amount;
                } else {
                    splitAmount.push(0)
                    totalAmount -= amount;
                }
            }
        }

        return splits;
    }

    useEffect(() => {
        getEmployee();
    }, [])

    const onChange = (e) => {
        if (e.target.id === "image_path") {
            const file = e.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {

            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!");
                return;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return;

            }
            setFormData({
                ...formData,
                [e.target.id]: file,
                update_passport: true
            })
            return;
        }

        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        toast.info("please wait...")
        const SendData = {
            ...formData,
            password: formData.phone_number
        }

        await axios.patch(`${API_URL}human-resources/employee/update`, SendData, login?.token)
            .then(async (res) => {
                if (res.data.message === "success") {
                    if (formData.update_passport === true) {
                        const dt = new FormData();
                        dt.append("file", formData.image_path)
                        dt.append("email_address", formData.email_address)
                        await axios.patch(`${API_URL}human-resources/employee/update/passport`, dt)
                            .then((res) => {
                            })
                    }
                    getEmployee();
                    toast.success("employee added successfully");
                }
                setIsSubmitting(false)
            }).catch((e) => {
                setIsSubmitting(false)
                toast.error(e.response.data)
            })
    }

    const [checkChange, setCheckChage] = useState('')
    useEffect(() => {
        setemployeeEmoluments(employeeEmoluments)
    }, [checkChange])

    if (isLoading) {
        return <Loader />
    }
    return (
        <>
            <GeneralToolbar title="Manage Employee"
                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                { name: 'Employee Details', link: '/human-resources/employee-management' }]}
                button={
                    <div className='d-flex'>
                        {/* <button className="btn btn-primary" onClick={() => { onOpenModal(true); setIsSubmitting(false) }}>Add Employee</button>
                        <button className="btn btn-sm btn-secondary ms-4" onClick={() => { setShowUpload(!showUpload) }}>Bulk Upload</button> */}
                    </div>
                }
            />
            <div className='container'>
                <div className='card'>
                    {
                        employeeData.length > 0 &&
                        <div className="d-flex flex-wrap flex-sm-nowrap mt-5">
                            <div className="me-7 mb-4">
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    <img src={employeeData[0].image_path !== null ? `${API_URL}public/staff/${employeeData[0].image_path}` : NoImage} alt="image" />
                                    <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">
                                                {employeeData[0]?.full_name}
                                            </a>
                                        </div>
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                                                        <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor" />
                                                        <rect x={7} y={6} width={4} height={4} rx={2} fill="currentColor" />
                                                    </svg>
                                                </span>
                                                {
                                                    props.titlesList?.filter(x => x.title_id === employeeData[0]?.job_title_id)[0]?.title
                                                }</a>

                                            <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                                                        <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                {employeeData[0]?.email_address}
                                            </a>
                                        </div>
                                        {/*end::Info*/}
                                    </div>

                                </div>
                                <div className="d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{employeeData[0]?.department_name}</div>
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">Department</div>
                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{currencyConverter(employeeData[0]?.salary)}</div>
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">Salary</div>
                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{employeeData[0]?.account_number}</div>
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">{employeeData[0]?.bank_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="card h-md-100">
                        {/*begin::Card header*/}
                        <div className="card-header position-relative py-0 border-bottom-1">
                            {/*begin::Card title*/}
                            <h3 className="card-title text-gray-800 fw-bold">Active Tasks</h3>
                            {/*end::Card title*/}
                            {/*begin::Tabs*/}
                            <ul
                                className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-4"
                                role="tablist"
                            >
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                    <a
                                        className="nav-link btn btn-color-gray-400 flex-center px-3 active"
                                        data-bs-toggle="tab"
                                        href="#profile"
                                        aria-selected="false"
                                        role="tab"
                                        tabIndex={-1}
                                    >
                                        <span className="nav-text fw-semibold fs-4 mb-3">Employee Profile</span>
                                        <span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-primary rounded" />
                                    </a>
                                </li>

                                <li className="nav-item p-0 ms-0" role="presentation">
                                    <a
                                        className="nav-link btn btn-color-gray-400 flex-center px-3"
                                        data-bs-toggle="tab"
                                        href="#settings"
                                        aria-selected="false"
                                        role="tab"
                                        tabIndex={-1}
                                    >
                                        <span className="nav-text fw-semibold fs-4 mb-3">Income and Emoluments</span>
                                        <span className="bullet-custom position-absolute z-index-2 w-100 h-1px top-100 bottom-n100 bg-primary rounded" />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="card-body pb-0">
                            <div className="tab-content">
                                <div
                                    className="tab-pane blockui active"
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="day-tab"
                                    data-kt-timeline-widget-4-blockui="true"
                                    style={{}}
                                >
                                    <div className="table-responsive pb-10">
                                        <EmployeesForm
                                            values={formData}
                                            onChange={onChange}
                                            onSubmit={onSubmit}
                                            departmentList={departmentList}
                                            titlesList={titlesList}
                                            isSubmitting={isSubmitting}
                                        />

                                    </div>
                                </div>
                                <div
                                    className="tab-pane blockui"
                                    id="settings"
                                    role="tabpanel"
                                    aria-labelledby="week-tab"
                                    data-kt-timeline-widget-4-blockui="true"
                                    style={{}}
                                >
                                    <div className="table-responsive pb-10">
                                        <EmployeeEmoluments
                                            emolumentSettings={emolumentSettings}
                                            employee_id={employee_id}
                                            inserted_by={login?.employee_id}
                                            employeeEmoluments={employeeEmoluments}
                                            setemployeeEmoluments={setemployeeEmoluments}
                                            setEmolumentsSettings={setEmolumentsSettings}
                                            formData={formData}
                                            setFormData={setFormData}
                                            setCheckChage={setCheckChage}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}



const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(EmployeeDetails);
