import React from 'react'

export default function DesignationForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="designation_name">Designation Name</label>
                        <input type="text" id="designation_name" className="form-control" value={props.values.designation_name} onChange={props.onChange} required />
                    </div>
                </div>
                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
