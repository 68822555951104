import React from "react";
import JoditEditor from "jodit-react";
import {API_URL} from "../../../../context/connection-string";

export default function SliderForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id={"title"}
                        onChange={props.onChange}
                        value={props.value.title}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="caption">Caption</label>
                    <input
                        type="text"
                        id={"caption"}
                        onChange={props.onChange}
                        value={props.value.caption}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="vision">Vision</label>
                    <input
                        type="text"
                        id={"vision"}
                        onChange={props.onChange}
                        value={props.value.vision}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="mission">Mission</label>
                    <input
                        type="text"
                        id={"mission"}
                        onChange={props.onChange}
                        value={props.value.mission}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="brand_personality">brand_personality</label>
                    <input
                    type="text"
                    id={"brand_personality"}
                    onChange={props.onChange}
                    value={props.value.brand_personality}
                    className={"form-control"}
                    placeholder={""}
                    />
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="image_path">Upload Image</label>
                        <input type="file" id="image_path" name={"image_path"} accept="image/*" className="form-control" onChange={props.onChange}/>
                    </div>
                </div>
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <JoditEditor
                            id={"description"} name={"description"} value={props.value.description} tabIndex={1} onChange={props.onDescriptionChange}
                        />
                    </div>
                </div>

                {
                    props.isFormLoading ?
                        <button id="kt_docs_formvalidation_text_submit" type="button" className="btn btn-primary">
                            <span> Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"/> </span>
                        </button>
                        :
                        <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
                }
            </div>
        </form>
    )
}
