import React from 'react'

export default function DivisionsForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="division_name">Division Name</label>
                        <input type="text" id="division_name" className="form-control" value={props.values.division_name} onChange={props.onChange} required />
                    </div>
                </div>

                <div className="col-md-12 pb-3 mt-5 mb-3">
                    <div className="form-group">
                        <label htmlFor="manager_id">Division Head</label>
                        <select className='form-control' id="division_head" onChange={props.onChange} value={props.values.division_head} required  >
                            <option value={""}> -Select Division Head- </option>
                            {
                                props.employeeList.length > 0 &&
                                props.employeeList?.map((x, i) => {
                                    return (
                                        <option key={i} value={x.employee_id}> {x.employee_id} -- {x.full_name} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
