import {HOTEL_CODE} from "../context/connection-string";

export const LoginDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${HOTEL_CODE}_LOGIN_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};
export const PermissionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${HOTEL_CODE}_PERMISSION_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};
export const GeneralDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case `SET_${HOTEL_CODE}_GENERAL_DETAILS`:
            return action.payload;
        default:
            return state;
    }
};