import React, {useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import {API_URL} from "../../../context/connection-string";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {Alert} from "@mui/material";
import {formatDateAndTime} from "../../../context/function";
import AllRoomBookingCalenderView from "../../front-desk/booking/room-calender-view/all-room-booking-calender-view";
import {DashboardPageTitle} from "./dashboard-page-title";

function DashboardFrontDesk({loginData}) {
    const [isLoading, setIsLoading] = useState(true);
    const [bookingList, setBookingList] = useState([])
    const [bookingActionList, setBookingActionList] = useState([])
    const [stats, setStats] = useState({
        totalRoom: 0, availableRoom: 0, checkInList: 0, checkOutList: 0, cancelledList: 0, latestBooking:0
    })

    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/front-desk-dashboard/${loginData[0].branch_id}`, loginData[0].token)
            .then(res => {
                setBookingList(res.data.booking_list)
                setBookingActionList(res.data.booking_action)
                setStats({
                    totalRoom: res.data.room_list,
                    availableRoom: res.data.available_room_list,
                    checkInList: res.data.booking_action.filter(i=>i.status==='checked_in').length,
                    checkOutList: res.data.booking_action.filter(i=>i.status==='checked_out').length,
                    cancelledList: res.data.booking_action.filter(i=>i.status==='cancelled').length,
                    latestBooking: res.data.booking_list.filter(i=>i.status==='booked').length
                })
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useState(()=>{
        fetchRecord()
    },[])

    return isLoading ? <Loader /> : (
        <>
            <DashboardPageTitle title="Front Desk" />
            <div className="row g-5 g-xl-12 pt-5">
                <div className="col-xl-4">
                    <span className="card bg-success hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
													<path opacity="0.3"
                                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                                          fill="currentColor"/>
													<path
                                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                                        fill="currentColor"/>
												</svg>
											</span>
                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.totalRoom}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Total Number of Rooms</div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-4">
                    <span className="card bg-primary hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
													<path opacity="0.3"
                                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                                          fill="currentColor"/>
													<path
                                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                                        fill="currentColor"/>
												</svg>
											</span>
                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.availableRoom}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Available Rooms</div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-4">
                    <span className="card bg-info hoverable card-xl-stretch mb-5 mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
													<path opacity="0.3"
                                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                                          fill="currentColor"/>
													<path
                                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                                        fill="currentColor"/>
												</svg>
											</span>
                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{stats.checkInList}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Checked In</div>
                        </div>
                    </span>
                </div>
            </div>

            <div className="row g-5 g-lg-12 ">
                <div className="col-xxl-4 col-md-4 mb-xxl-10">
                    <div className="card h-md-100">
                        <div className="card-body p-0">
                            <div className="px-9 pt-7 card-rounded h-275px w-100 bg-primary">
                                <div className="d-flex flex-stack">
                                    <h3 className="m-0 text-white fw-bold fs-3">Front Desk</h3>
                                </div>
                                <div className="d-flex text-center flex-column text-white pt-8">
                                    <span className="fw-semibold fs-7">Latest Bookings</span>
                                    <span className="fw-bold fs-2x pt-1">{stats.latestBooking}</span>
                                </div>
                            </div>
                            <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style= {{marginTop:'-100px'}}>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                          fill="currentColor"/>
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Checked In</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.checkInList}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                          fill="currentColor"/>
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Checked Out</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.checkOutList}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="symbol symbol-45px w-40px me-5">
                                        <span className="symbol-label bg-lighten">
                                            <span className="svg-icon svg-icon-1">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="2" y="2" width="9" height="9" rx="2"
                                                          fill="currentColor"/>
                                                    <rect opacity="0.3" x="13" y="2" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                    <rect opacity="0.3" x="13" y="13" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                    <rect opacity="0.3" x="2" y="13" width="9"
                                                          height="9" rx="2" fill="currentColor"/>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <span className="fs-5 text-gray-800 text-hover-primary fw-bold">Cancelled</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="fw-bold fs-5 text-gray-800 pe-1">{stats.cancelledList}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-xxl-8 mb-5 mb-xl-10">
                    <div className="card card-flush h-xl-100">
                        <div className="card-header py-7">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 ">
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                       href="#latest_booking">Latest Booking</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                       href="#checked_in">Checked In</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                       href="#checked_out">Checked Out</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                       href="#all_booking">All Bookings</a>
                                </li>
                            </ul>
                            <div className="card-toolbar">
                                <Link to="/front-desk/add-booking" className="btn btn-primary">Add Booking</Link>
                            </div>
                        </div>
                        <div className="card-body pt-1 tab-content">
                            <div className="separator separator-dashed my-5" />
                            <div className="tab-pane fade show active" id="latest_booking">
                                {
                                    bookingList.filter(i=>i.status === 'booked').length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.filter(r=>r.status === 'booked').map((r,index) => {
                                                    if (index < 5)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>

                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                            <div className="tab-pane fade" id="checked_in">
                                {
                                    bookingList.filter(i=>i.status === 'checked_in').length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.filter(r=>r.status === 'checked_in').map((r,index) => {
                                                    if (index < 5)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>

                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                            <div className="tab-pane fade" id="checked_out">
                                {
                                    bookingList.filter(i=>i.status === 'checked_out').length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.filter(r=>r.status === 'checked_out').map((r,index) => {
                                                    if (index < 5)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>

                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                            <div className="tab-pane fade" id="all_booking">
                                {
                                    bookingList.length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.map((r,index) => {
                                                    if (index < 5)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>

                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row g-5 g-xl-12 pt-5">
                <AllRoomBookingCalenderView booking={bookingList} />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardFrontDesk);
