import React from "react";
import { connect } from "react-redux";
import DashboardFrontDesk from "./dashboard-component/dashboard-front-desk";
import DashboardHumanResources from "./dashboard-component/dashboard-human-resources";
import DashboardPOS from "./dashboard-component/dashboard-pos";
import DashboardHouseKeeping from "./dashboard-component/dashboard-house-keeping";
import DashboardAccounting from "./dashboard-component/dashboard-accounting";
import DashboardFacilityAndServices from "./dashboard-component/dashboard-facility-and-services";
import DashboardAdmin from "./dashboard-component/dashboard-admin";
import DashboardDefault from "./dashboard-component/dashboard-default";
function Dashboard({loginData}) {
    const department = loginData[0].department_name
    return (
        department === 'Front Desk' ? <DashboardHumanResources />
        : department === 'Human Resources' ? <DashboardHumanResources />
        : department === 'POS' ? <DashboardHumanResources />
        : department === 'House Keeping' ? <DashboardHumanResources />
        : department === 'Accounting' ? <DashboardHumanResources />
        : department === 'Facility and Services' ? <DashboardHumanResources />
        : department === 'Admin' ? <DashboardHumanResources />
        : <DashboardHumanResources />
        // : <DashboardDefault />
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(Dashboard);
