import React from 'react'

export default function IncomeEmolumentsForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="department_name">Item Name</label>
                        <input type="text" id="item_name" className="form-control" value={props.values.item_name} onChange={props.onChange} required />
                    </div>
                </div>
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="is_basic">Is Category</label>
                        <select id='is_basic' className="form-control" value={props.values.is_basic} onChange={props.onChange} required >
                            <option value="">---select Is Category---</option>
                            <option value="basic">Basic</option>
                            <option value="housing">Housing</option>
                            <option value="transport">Transport</option>
                            <option value="others">Others</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="is_taxable">Is Taxable</label>
                        <select id='is_taxable' className="form-control" value={props.values.is_taxable} onChange={props.onChange} required >
                            <option value="">---select Is Taxable---</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="is_percentage">Is Percentage</label>
                        <select id='is_percentage' className="form-control" value={props.values.is_percentage} onChange={props.onChange} required >
                            <option value="">---select Is Percentage---</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                </div>
                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
